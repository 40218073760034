.footerContainer {
  position: relative;
  height: 9vw;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: $robotoB;

  &:after {
    content: " ";
    height: 1px;
    position: absolute;
    width: 100%;
    left: 0px;
    background-color: $primary-color;
  }

  &__logo {
    color: #747474;
    position: absolute;
    right: 50px;
    bottom: 1.4vw;

    a {
      color: #747474; 
      p {
        color: #747474;
      }
    }
  }
}

.list-footer {
  background-color: #fff;
  color: #747474;
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 630px;
  // margin: 0 auto;
  z-index: 10;

  a {
    color: #747474;
    transition: all 0.3s;

    &:hover {
      color: $primary-color;
    }
  }
}

@media screen and (max-width: 1250px){
  .footerContainer {
    height: 135px;
  }
}

@media screen and (max-width: 768px){
  .footerContainer {
    flex-direction: column;
    min-height: 200px;

    &:after {
      content: none;
    }

    &__logo {
      position: relative;
      margin: 0 auto;
      right: auto;
      bottom: auto;
    }
  }

  .list-footer {
    padding: 15px 0px;
    flex-direction: column;
    width: 100%;

    li {
      margin: 5px 0px;
    }
  }
}