.mainTitle {
  font-size: 36px;
  font-family: $menu-font;
  width: 1250px;
  margin: 0 auto;
  position: relative;
  padding-bottom: 30px;

  &:after {
    content: ' ';
    position: absolute;
    bottom: 0px;
    left: 0px;
    height: 1px;
    background-color: #000;
    width: 235px;
  }
}

.normalHeader {
  font-size: 36px;
  font-family: $menu-font;
  text-transform: uppercase;
}

.exampleText {
  font-family: $fontL;
  font-size: 16px;
  margin-top: 20px;
  margin-bottom: 40px;
}

@media screen and (max-width: 1251px){
  .mainTitle {
    width: 100%;
    // padding-left: 15px;
    margin-left: 15px;

    &:after {
      left: 0px;
    }
  }
}

@media screen and (max-width: 768px){
  .mainTitle {
    font-size: 24px;
  }  
  .normalHeader {
    font-size: 24px;
  }
}