.otherBlogs {
  background-color: #fff;
  padding-top: 0px;

  .swiper-slider-realizationsPhoto {
    background-size: cover;
  }

  .swiper-slide {
    
    h3 {
      padding-left: 125px;
      font-size: 75px;
      font-family: $menu-font;
      margin-top: -50px;
    }
    p {
      padding-left: 125px;
    }
  }

  &__seeMore {
    position: relative;
    margin-left: 125px;
    left: auto;
    bottom: auto;
  }
}

@media screen and (max-width: 768px){
  .otherBlogs {

    .swiper-slide {
      h3 {
        padding-left: 0px;
      }
      p {
        padding-left: 0px;
      }
    }

    &__seeMore {
      margin-left: 0px;
    }
  }
}