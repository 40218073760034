.contact {

  p {
    font-family: $fontL;
    font-size: 16px;
    line-height: 32px;
  }
  a { 
    color: #fff;
  }
}

.mapaContact {
  position: relative;

  // &:before {
  //   content: '';
  //   width: 12px;
  //   height: 8vw;
  //   background-color: #f1f1f1;
  //   position: absolute;
  //   bottom: -10px;
  //   left: -10px;
  // }
}

.background-map {
  border-right: 12px solid #f1f1f1;
}

.border-map { 
  // width: 77.3%
  width: auto;
  height: 13px;
  background-color: #f1f1f1;
  margin-left: 32.7%;
}

.form {
  width: 100%;
  height: 830px;
  background-image: url(../images/kontakt.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin: 60px 0px;

  display: flex;

  &__title {
    width: 33.3333%;
    height: 100%;
    padding-top: 5vw;
  
    .mainTitle {
      width: auto;
      font-family: $menu-font;
      margin-left: 100px;
    }
  }

  &__middle {
    width: 26.3333%;
    height: 100%;
    padding-top: 10vw;
    padding-right: 7%;
  
 
    &--input {
      margin-bottom: 40px;
      
      h4 {
        font-family: $fontR;
        font-size: 14px;
        margin-bottom: 15px;
        transition: all 0.3s;
      }

      input {
        background-color: transparent;
        border: none;
        width: 100%;
        border-bottom: 1px solid #000;
        color: #000;
        margin-bottom: 10px;


        &:focus {
          outline: none;
        }
      }

      textarea {
        background-color: transparent;
        border: none;
        width: 100%;
        border-bottom: 1px solid #000;
        height: 100px;
        font-family: $fontL;

        &:focus {
          outline: none;
        }
      }
    }
  }

  .headerActive {
    color: $primary-color;
    font-weight: bold;
    transform: scale(1.2);
  }

  &__right {
    width: 28.3333%;
    height: 100%;
    padding-right: 7%;
    padding-top: 10vw;
    
  }
}

.error {
  color: $primary-color;
  font-family: $fontR;
  font-size: 14px;
}

#sendMessage {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: none;
  padding: 20px 0px;
  margin-top: 100px;
  position: relative;
  font-family: $fontR;
  text-align: center;

  border-bottom: 10px solid $primary-color;

  &:focus {
    outline: none;
  }

  &:hover {
    cursor: pointer;
    color: #fff;
  }
}

#sendMessage::after {
  content: "";
  position: absolute;
  bottom: -10px;
  left: 0;
  width: 100%;
  height: 100%;
  transform: scaleY(0);
  transform-origin: bottom center;
  background-color: $primary-color;
  z-index: 1;
  transition: all 0.3s;
}

#sendMessage:hover::after {
    transform: scaleY(1);
    z-index: 1;
    bottom: 0px;
}

#sendMessage:hover {
  p {
    color: #fff;
    z-index: 10;
  }
}

@media screen and (max-width: 1500px){
  .form {
    height: 530px;
  } 
  .border-map { 
    display: none;
  }
  .background-map {
    border: none;
  }
}

@media screen and (max-width: 1300px){
  .form {
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0px;
    height: 840px;
    margin: 5px 0px;
    padding-bottom: 30px;

    // display: block;
    padding-left: 15px;

    &__middle {
      padding: 0px;
      width: 500px;
      height: auto;
    }

    &__right {
      width: 500px;
      padding: 0px;
      height: auto;
    }

    &__title {
      width: 500px;
      height: auto;
      margin: 40px 0px;
      padding-top: 0px;
      .mainTitle {
        margin-left: 0px;
      }
    }
  }
  #sendMessage {
    margin-top: 50px;
  }
  .contactHeader {
    // flex-direction: column;
    // min-height: 730px;

    &__left {
      width: 100%;
      .mainPhotoHeader__left--background {
        min-height: 340px;
      }
      .mainPhotoHeader__left--scroll {
        display: none;
      }
    }
    .mainPhotoHeader__right {
      width: 100%;
      min-height: 400px;
    }
  }
}

@media screen and (max-width: 1100px){
  .contactHeader {
    flex-direction: column;
  }
  .mainPhotoHeader {
    height: auto;
  }
  .mainPhotoHeader__left--background {
    // height: auto;
  }
}

@media screen and (max-width: 768px){
  .contact {
    padding: 15px;
  }
  .form {
    height: auto;
    h4 {
      transform-origin: 0% 10%;
    }

    &__title {
      padding-top: 20px;
    }
  }
}

@media screen and (max-width: 550px){
  .contactHeader {
    height: auto;
    
    .mainPhotoHeader__left--background {
      height: auto;
    }
  }
  .contact {
    position: relative;

    p {
      font-size: 14px;
    }
  }
  .form {
    // padding: 15px;
    margin-bottom: 0px;

    &__middle {
      width: 100%;
    }

    &__right {
      width: 100%;
    }

    &__title {
      width: 100%;
      // min-height: 400px;
      .mainTitle {
        margin-left: 0px;
      }
    }
  }
}