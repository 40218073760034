// @media screen and (max-width: 1600px){
//   .border-map {
//     width: auto;
//   }
// }

@media screen and (max-width: 1300px){
  .realizationsSwiper .realizationsPagination {
    left: 0px;
  }
}

@media screen and (max-width: 1024px){
  .footerContainer {

    &:after {
      height: 0px;
    }
  }
  .newScroll {
    left: 15px;
  }
  // .aboutUs__swiper {
  //   padding: 0 15px;
  // }
  .aboutUs__swiper--pagination {
    width: 300px !important;
    left: 15px !important;
    right: auto !important;
  }
  .aboutUs__count {
    left: 15px;
  }
  .visualisation {
    height: 500px;
  }
  .mainContainerNew {
    padding: 0 15px;
  }
  .aboutUs__content p {
    width: 450px;
  }
  .mainPhotoHeader__left--scroll {
    // padding-left: 15px;
  }
  .mainContainerPortfolio__box--left {
    padding: 0px;
    min-height: 190px;
  }
  #MixItUp7AC9E8 {
    padding: 15px;
  }
  .mainPhotoHeader__left--background--text {
    font-size: 24px;
  }
  .otherProjects {
    padding-right: 0px;
    justify-content: space-around;
  }
  .mainPhotoHeader__left--background--text h2 {
    font-size: 28px;
  }
  .mainPhotoHeader__left--background--text h3 {
    font-size: 17px;
  }
  .howWeWork {
    padding-left: 15px;
    padding-right: 15px;
  }
  .aboutUs__content h3 {
    width: 450px;
  }
  .blog-box-left .mainContainerPortfolio__box--left {
    padding-left: 0px;
    padding-right: 0px;
  }
  .containerBlog  {
    margin-top: 50px;
  }
  .containerBlog .mainContainerPortfolio__box--left h3 {
    padding-top: 30px;
  }
  .containerBlog__box .mainContainerPortfolio__box--left .seeRealization {
    margin-bottom: 25px;
  }
  .containerBlog__box {
    margin-bottom: 0px;
  }
  .pagination {
    margin-top: 50px;
  }
  .form {
    height: auto;
    padding-bottom: 20px;
  }
  .contactHeader .mainPhotoHeader__right {
    height: 400px;
    min-height: auto;
  }
  .contactHeader__left .mainPhotoHeader__left--background {
    height: 340px;
    min-height: auto;
  }
  .lastRealization {
    min-height: 550px;
  }
  .lastRealizationContent {
    padding-left: 15px;
  }
  .lastRealization__seeMore {
    left: 0px;
  }
  .containerOnas__seeMore {
    padding-bottom: 50px;
  }
  .mainContainerPortfolio__box--left {
    h3 {
      padding-right: 15px;
      padding-top: 15px;
    }
    p {
      padding-right: 15px;
      width: 80%;
    }
    .seeRealization {
      margin-bottom: 20px;
      margin-right: 15px;
    }
  }
  .mainTitle {
    width: 80%;
  }
  .newContainerPadding {
    padding: 0 15px;
  }
  .newScroll {
    width: 100%;
  }
  .mainPhotoHeader__left--scroll {
    margin-left: 15px;
  }
  .aboutUs {

    .mainTitle {
      margin-left: 0px;
    }
  }
  .visualisation .swiper-slide {
    height: 500px;
  }
  .otherBlogs {

    .lastRealization__header {
      left: 0px;
    }


  }
  .realizationsSwiper .swiper-slider-realizationsPhoto {
    height: 400px;
  }
  .lastRealization {
    min-height: 770px;
  }
  .containerBlog__box {
    height: 750px;
  }
  .otherBlogs .swiper-slide h3 {
    font-size: 45px;
  }
}

@media screen and (max-width: 768px){

  .mainPhotoHeader__left--scroll {
    margin-left: 0px;
  }
  .mainContainerPortfolio__box--left h3 {
    width: 90%;
    font-size: 26px;
  }
  .aboutUs__content p {
    width: 300px;
  }
  .visualisation {
    height: auto;
  }
  .mainContainerPortfolio__box--left {
    h3 {
      padding: 15px 15px 0px 0px;
    }
    p {
      padding-right: 15px;
      padding-left: 15px;
    }
    .seeRealization {
      margin-right: 15px;
      margin-bottom: 15px;
    }
  }
  .singleRealizacja-photo {
    height: 380px;
  }
  .containerOnas h3 {
    font-size: 24px;
  }
  .containerOnasNew {
    padding: 25px 15px;
  }
  .realizationsSwiper .swiper-slider-realizationsPhoto {
    height: 260px;
  }
  .realizationsSwiper h3 {
   font-size: 24px;
  }
  .aboutUs__swiper {
    padding: 0px;
  }
  .otherBlogs .swiper-slide h3 {
    font-size: 30px;
    margin-top: -50px;
    // margin-left: 15px;
    margin-bottom: 35px;
  }
  .otherBlogs .swiper-slide p {
    // padding-left: 15px;
  }
  .realizationsSwiper .swiper-slider-realizationsPhoto {
    margin-left: 0px;
  }
  .aboutUs__content {
    h3 {
      width: 100%;
    }
    p {
      width: 100%;
    } 
  }

  .aboutUs {
    padding-left: 0px;
    padding-right: 0px;
  }
  .mainTitle {
    margin-bottom: 20px;
  }
  .mainContainerNew {
    padding: 0px;
  }
  .realizationsSwiper .realizationsPagination {
    left: 0px;
  }
  .lastRealization__header {
    left: 0px;
  }
  .realizationsSwiper h3 {
    margin: 20px 0px;
    padding-left: 0px;
  }
  .realizationsSwiper p {
    padding-left: 0px;
  }
  .realizationsSwiper .swiper-slider-realizationsPhoto {
    width: 97%;
  }
  .form {
    padding: 0px;
    padding-bottom: 20px;
  }
  .contactHeader__left .mainPhotoHeader__left--background {
    height: 350px;
  }
  .form__middle {
    width: 290px;
    margin: 0 auto;
  }
  .form__right {
    width: 290px;
    margin: 0 auto;
  }
  .form__title {
    width: 290px;
    margin: 0 auto;
  }
  .otherBlogs .realizationsSwiper .swiper-slider-realizationsPhoto {
    height: 430px;
  }

  .otherBlogs .swiper-slider {
    padding-bottom: 20px;
  }
  .mainMenu {
    position: inherit;
  }
  .aboutUs__swiper {
    height: 440px;
  }
  .visualisation .swiper-slide {
    height: 280px;
  }
  .mainTitle {
    font-size: 20px;
  }
  .aboutUs__content h3 {
    font-size: 20px;
  }
  .aboutUs__count {
    font-size: 20px;
  }
  .realizationsSwiper h3 {
    font-size: 20px;
  }
  .lastRealization__header {
    font-size: 24px;
  }
  .realizationsSwiper {
    padding-top: 140px;
  }
  .mainContainerPortfolio ul li {
    font-size: 20px;
  }
  .mainContainerPortfolio__box--left p {
    display: none;
  }
  .mainContainerPortfolio__box--left {
    min-height: 100px;
  }
  .mainContainerPortfolio__box--left h3 {
    margin-bottom: 20px;
    font-size: 20px;
  }
  .singleRealizacja-photo {
    height: 64vw;
  }
  .mainPhotoHeader__left--background--text {
    font-size: 20px;
  }
  .normalHeader {
    font-size: 20px;
  }
  .mainPhotoHeader__left--background--text h2 {
    font-size: 22px;
  }
  .containerOnas h3 {
    font-size: 20px;
  }
  .mainPhotoHeader__text {
    font-size: 24px;
  }
  .containerOnasGrey__count--single h4 {
    font-size: 40px;
  }
  .countM2 h6 {
    font-size: 30px;
    margin-bottom: 11px;
  }
}

@media screen and (min-width: 769px){
  .mainPhotoHeader, .mainPhoto {
    margin-top: 95px;
  }
}

@media screen and (max-width: 1320px) and (min-width: 1250px) {
  .mainContainer {
   padding-left: 50px;
   padding-right: 50px;
   width: auto;
  }
  .containerOnas {
    padding-left: 25.8%;

    p {
      padding-left: 0px;
    }
  }
  .newScroll {
    left: 60px;
  }
  .mainTitle {
    margin-left: 60px;
  }
  .aboutUs__swiper--pagination  {
    left: 60px !important; 
  }
  .lastRealization__count {
    left: 60px;
  }
  .realizationsSwiper .swiper-slider-realizationsPhoto {
    height: 550px;
  }
  .realizationsSwiper .realizationsPagination {
    left: 50px;
  }
  .lastRealization__seeMore {
    left: 50px;
  }
  .visualisation .aboutUs__count {
    left: 60px;
  }
  .containerOnasGrey__count {
    width: auto;
  }
  .mainTitleNew {
    width: auto;
  }
  .swiper-slide  {

    .mainContainer {
      padding: 0px;
      width: 1150px;
    }
  }
}