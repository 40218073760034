.top-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 95px 30px 35px;
  font-family: $menu-font;
  position: relative;
  transition: all 0.3s;

  &__left {

    .site-logo {
      background-image: url(../images/logo.svg);
      background-size: contain;
      background-repeat: no-repeat;
      width: 180px;
      height: 35px;
    }
  }

  &__right {
    display: flex;

    &--list {
      display: flex;
      margin-right: 45px;
      visibility: hidden;

      li {
        padding: 0px 15px;

        a {
          color: $secondary-color;
          transition: all 0.3s;
  
          &:hover {
            color: $primary-color;
          }
        }
      }
    }

    &--social {
      display: flex;
      justify-content: space-around;
      align-items: center;
      padding-left: 25px;
      width: 95px;
      border-left: 1px solid #747474;
      position: absolute;
      top: 0px;
      right: 0px;
      height: 95px;
      transition: all 0.3s;

      a {
        width: 25px;
        height: 25px;

        svg {
         path { 
           transition: all 0.3s; 
         }
            
          &:hover {
            path {
              fill: $primary-color;
            }
          }
        }

      }
    }
  }
}

.menu {
  
  &__list {
    display: flex;
    justify-content: space-between;
    width: 700px;

    li {

      a {
        color: $secondary-color;
        text-transform: uppercase;

        font-size: 16px;
        transition: all 0.3s;

        &:hover {
          color: $primary-color;
        }
      }
    }
  }
}

.menu__element--active {
  color: $primary-color !important;
}

//sticky menu 

.mainMenuActive {
  position: fixed;
  z-index: 99999999999;

}

.mainMenu {
  transition: all 0.3s;
  width: 100%;
  top: 0;
  background-color: #fff;
}

.top-barActive {
  padding-top: 20px;
  padding-bottom: 20px;
  // box-shadow: 0 4px 2px -2px gray;
  border-bottom: 1px solid #747474;
}

.social-active {
  height: 75px;
}

/////////////////

@media screen and (max-width: 1250px){
  .menu {

    &__list {
      width: 500px;
    }
  }
}

@media screen and (max-width: 1000px){
  .top-bar {
    padding: 40px 95px 40px 15px;

    &__right {

      &--social {
        padding-left: 15px;
        padding-right: 15px;
        width: 60px;
        height: 115px;
      }

      &--list {
        flex-direction: column;
        align-items: center;
        margin-right: 5px;

        li {
          padding: 0px 5px;
        }
      }
    }
  }
  .menu {

    &__list {
      width: 400px;
    }
  }
}

@media screen and (max-width: 768px){
  .mainMenu { 
    display: none;
    transition: unset;
  }

  .top-bar {
    display: flex;
    flex-direction: column;
    padding: 15px 0px ;

    &__right {
      flex-direction: column;

      &--social {
        position: relative;
        height: 40px;
        border-left: 0px;
      }

      &--list {
        margin: 0px 0px 10px 0px;
        display: none;
        
        li {
          margin: 5px 0px;
        }
      }
    }
  }

  .menu {

    &__list {
      flex-direction: column;
      align-items: center;
      margin: 15px 0px;

      li {
        margin: 5px 0px;
      }
    }
  }
}