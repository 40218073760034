.mainContainerPortfolio {
  
  &__box {
    width: 100%;
    min-height: 530px;
    display: flex;
    text-align: right;
    margin-bottom: 28px;

    a {
      width: 100%;
    }

    &--left {
      width: 400px;
      min-height: 530px;
      background-color: #f2f2f2;
      flex-shrink: 0;
      display: flex;
      align-items: flex-end;
      flex-direction: column;
      padding: 90px 100px 0px 100px;

      h3 {
        font-family: $menu-font;
        font-size: 36px;
        text-transform: uppercase;
        width: 100%;
        // padding: 90px 100px 0px 100px;
      }

      p {
        font-family: $fontR;
        font-size: 14px;
        width: 100%;
        // padding: 60px 100px 20px 100px;
        padding-top: 60px;
        padding-bottom: 20px;
      }
    }

    &--right {
      // background-image: url(../images/realizacja.png);
      background-size: cover;
      background-repeat: no-repeat;
      min-height: 100%;
      // width: calc(100% - 600px);
      width: 100%;
    }
  }
}

.seeRealization {
  font-size: 16px;
  width: 175px;
  // margin-right: 100px;
  padding-bottom: 10px;
  border-bottom: 5px solid $primary-color;
  a {
    font-family: $menu-font;
    padding: 0px;
    transition: all 0.3s;

    &:hover {
      color: $primary-color;
    }

  }
}

.portfolioHover {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.portfolioChildHover {
  transition: all .3s;
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
}

.portfolioHover:hover .portfolioChildHover,
.portfolioHover:focus .portfolioChildHover {
  transform: scale(1.2);
}

@media screen and (max-width: 1600px){
  .mainContainerPortfolio {

    &__box {
      min-height: auto;
      width: 94%;
      margin-left: auto;
      margin-right: auto;

      &--left {
        min-height: auto;
        padding-bottom: 40px;
      }
    }
  }
}

@media screen and (max-width: 1200px){
  .mainContainerPortfolio {
    &__box {
      flex-direction: column;
  
      &--left {
        width: 100%;
        padding-bottom: 40px;
        padding-left: 0px;
        padding-right: 0px;

        h3 {
          padding-right: 20px;
        }
        p {
          padding-right: 20px;
        }
        .seeRealization  {
          margin-right: 20px;
        }
      }
  
      &--right {
        height: 500px;
      }
    }
  }
}

@media screen and (max-width: 768px){
  .mainContainerPortfolio {
    &__box {
  
      &--left {
        min-height: auto;
        padding: 0px;
        padding-bottom: 40px;
        h3{
          font-size: 32px;
        }
      }

      &--right {
        height: 250px;
      }
    }
  }
}


@media screen and (max-width: 500px){
  .mainContainerPortfolio {
    &__box {
  
      &--left {
       
        h3, p {
          padding: 15px;
        }

        .seeRealization {
          margin-right: 15px;
        }
      }
  
    }
  }
}