.mainContainerPortfolio {
  width: 1600px;
  margin: 0 auto;
  padding-top: 8vw;
  
  ul {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 80px;
    li {
      text-transform: uppercase;
      margin-right: 60px;
      font-size: 36px;
      transition: all 0.3s;
      font-family: $menu-font;
      margin-bottom: 20px;

      &:hover {
        color: $primary-color;
        cursor: pointer;
      }
    }
  }
}

.swiperPortfolio {
  position: inherit;
  .swiper-pagination-portfolio {
    top: 80px;
    left: 17%;
    width: 200px;
  }
  .aboutUs__count {
    top: 80px;
    left: 5%;

    .aboutUs__count--full, .aboutUs-slash {
      color: #fff;
    }
  }
  .swiper-pagination-bullet {
    width: 18px;
    height: 4px;
    border-radius: 0px;
    margin: 0px 15px !important;
    background-color: #fff;

    &:first-child {
      margin-left: 0px !important;
    }
  }
  .swiper-pagination-bullet-active {
    background-color: $primary-color;
  }
  .slidePort {
    background-repeat: no-repeat;
    background-size: cover; 
  }
}


@media screen and (max-width: 1601px){

  .mainContainerPortfolio {
    width: 100%;
    // margin: 0px 15px;

    ul {
      padding-left: 65px;
      li {
        font-size: 24px;
      }
    }
  }
}

@media screen and (max-width: 1200px){
  .swiperPortfolio {
    position: inherit;
    .swiper-pagination-portfolio {
      top: 280px;
      left: 11%;
      width: 200px;
    }
    .aboutUs__count {
      top: 280px;
      left: 5%;
    }
  }
  
}

@media screen and (max-width: 768px){
  .mainContainerPortfolio  {

    ul {
      flex-direction: column;
      padding-left: 15px;
      margin-bottom: 30px;
    }
  }

  .swiperPortfolio {
    position: inherit;
    .swiper-pagination-portfolio {
      top: 215px;
      left: 4%;
      width: 123px;
    }
    .swiper-pagination-bullet {
      margin: 0 5px !important;
    }
    .aboutUs__count {
      top: 240px;
      left: 5%;
    }
  }
  
}