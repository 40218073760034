// If you have any variables in your code, place it here


//Colors

$primary-color: #4ad681;
$secondary-color: #3a3a3a;
$alert-color: red;
$success-color: green;

// Box shadows 

$small-box-shadow : 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
$medium-box-shadow : 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);

// fonts 

@font-face {
  font-family: Montserrat-Bold;
  src: url(../fonts/Montserrat-Bold.ttf);
}

@font-face {
  font-family: Montserrat-SemiBold;
  src: url(../fonts/Montserrat-SemiBold.ttf);
}

@font-face {
  font-family: Montserrat-Light;
  src: url(../fonts/Montserrat-Light.ttf);
}

@font-face {
  font-family: Montserrat-Medium;
  src: url(../fonts/Montserrat-Medium.ttf);
}

@font-face {
  font-family: Montserrat-Regular;
  src: url(../fonts/Montserrat-Regular.ttf);
}

@font-face {
  font-family: Roboto-Bold;
  src: url(../fonts/Roboto-Bold.ttf);
}

$menu-font: 'Montserrat-Bold', sans-serif;
$fontSB: 'Montserrat-SemiBold', sans-serif;
$fontL: 'Montserrat-Light', sans-serif;
$fontM: 'Montserrat-Medium', sans-serif;
$fontR: 'Montserrat-Regular', sans-serif;
$robotoB: 'Roboto-Bold', sans-serif;