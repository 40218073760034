.mainContainer {
  width: 1250px;
  margin: 0 auto;
}

.mainContainerPortfolio {
  width: 1600px;
  margin: 0 auto;
}




@media screen and (max-width: 1251px){
  .mainContainer {
    width: 100%;
    // padding: 0px 15px;
  }

  .mainContainerPortfolio {
    width: 100%;
    margin: 0 auto;
  }
}