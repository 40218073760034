.lastRealizationContent {
  background-color: #f1f1f1;
  padding-top: 50px;
  position: relative;
  
}

.lastRealization {
  // height: 1080px;
  height: 1px;
  min-height: 1000px;
  position: relative;
  display: flex;
  justify-content: flex-end;
 

  &__header {
    position: absolute;
    left: 0px;
    top: 125px; 
    font-family: $fontSB;
    font-size: 36px;

    h3 {
      font-family: $menu-font;
    }
  }

  &__seeMore {
    position: absolute;
    bottom: 130px;
    left: 299px;
   
    a {
      font-size: 16px;
      font-family: $robotoB;
      padding-bottom: 15px;
      // width: 280px;
      position: relative;
      transition: all 0.3s;

      &:hover {
        color: $primary-color;
      }
      &:after {
        content: ' ';
        height: 4px;
        width: 280px;
        background-color: $primary-color;
        position: absolute;
        bottom: 0px;
        left: 0px;
      }
    }
  }

  &__count {
    bottom: auto;
    top: 39px;
  }
}

.realizationsSwiper {
  display: flex;
  justify-content: flex-end;
  width: 951px;
  margin: initial;
  position: initial;

    h3 {
      font-size: 36px;
      font-family: $fontSB;
      margin-bottom: 65px;
      margin-top: 45px;
    }

    p {
      font-size: 14px;
      font-family: $fontL;
      margin-bottom: 30px;
    }
  

  .swiper-pagination-bullet {
    width: 18px;
    height: 4px;
    border-radius: 0px;
    margin: 0px 10px !important;

    &:hover {
      cursor: pointer;
    }

    &:first-child {
      margin-left: 0px !important;
    }
  }
  .realizationsPagination {
    position: absolute;
    left: 0px;
    top: -10px;
    width: auto;
  }
  .swiper-button-next, .swiper-button-prev {
    top: 35.8%;
    background-size: contain;
    width: 14px;
    height: 27px;
  }
  .swiper-button-next {
    right: auto;
    left: 35px;
  }
  .swiper-button-prev {
    left: 0px;
  }
  .swiper-pagination-bullet-active {
    background-color: $primary-color;
  }
  .swiper-button-prev {
    background-image: url(../images/swiper-prev.svg);
  }
  .swiper-button-next {
    background-image: url(../images/swiper-next.svg);
  }
  .swiper-slider-realizationsPhoto {
    // background-image: url(../images/slider-2.png);
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 507px;
  }
  .swiper-wrapper {
    width: 951px;
    height: 507px;
  }
}

@media screen and (max-width: 1500px){
  .lastRealization{

    &__count {
      top: 75px;
      left: 49px;
    }
  }
}

@media screen and (max-width: 1300px){
  .lineSlider {
    display: none;
  }
  .lastRealization {
    justify-content: center;
    //  

    &__seeMore {
      left: 15px;
      bottom: 35px;
    }

    &__header {
      left: 220px;
      top: 0;
    }

    &__count {
      left: 15px;
    }
  } 
  .realizationsSwiper {
    width: 100%;
    padding-top: 100px;

    .swiper-wrapper {
      width: 100%;
    }

    .swiper-slider-realizationsPhoto {
      background-size: contain;
      // height: 150px;
    }

    .realizationsPagination {
      left: 15px;
    }
    
    .swiper-button-next {
      top: 5%;
      right: 50px;
      left: auto;
    }
    .swiper-button-prev {
      top: 5%;
      left: auto;
      right: 110px;
    }

  }
}

@media screen and (max-width: 768px){
  // .lastRealization__seeMore {
  //   display: none;
  // }
  .realizationsSwiper {
    padding-top: 170px;

    h3 {
      padding: 0 15px;
    }

    p {
      padding: 0 15px;
    }

    .swiper-slider-realizationsPhoto {
      margin-left: 15px;
    }

    .swiper-wrapper {
      height: auto;
    }
  }
  .lastRealization {
    height: auto;
    min-height: auto;


    &__header {
      left: 15px;
      top: 70px;
    }
  }
}