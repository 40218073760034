// .mainPhotoHeader-active {
//   margin-top: 76px;
// }

.mainMenu {
  position: fixed;
  z-index: 9999999;
}

.mainPhotoHeader {
  display: flex;
  height: 45vw;
  transition: all 0.3s;
  position: relative;

  &__white {
    width: 100%;
    height: 8vw;
    position: absolute;
    bottom: 0px;
  }

  &__left {
    width: 33.333%;

    &--background {
      background-color: #000;
      // background-color: #3a3a3a;
      height: 37vw;
      text-transform: uppercase;
     
      &--text {
        color: #fff;
        font-size: 36px;
        padding: 8vw 40px 0px 40px;
        line-height: 46px;

        h2 {
          font-family: $menu-font;
          text-transform: uppercase;
          padding-bottom: 3.5vw;
          min-width: 250px;
          position: relative;

          &:after {
            content: ' ';
            height: 1px; 
            width: 240px;
            background-color: #fff;
            position: absolute;
            bottom: 15px; 
            left: 0px;
          }
        }

        h3 {
          padding-top: 3.5vw;
          font-family: $menu-font;
          text-transform: uppercase;
          font-size: 28px;
        }

        h4 {
          font-family: $fontL;
  
          span {
            font-family: $fontSB;
          }
        }
      }

    }
    &--scroll {
      height: 8vw;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding-right: 30px;
      font-family: $menu-font;

      p {
        font-size: 14px;
        z-index: 2;
      }

      &--arrow {
        width: 12px;
        height: 15px;
        background-image: url(../images/arrow.svg);
        background-position: center;
        background-size: contain;
        margin-right: 15px;
        margin-bottom: 15px;
        transition: all 0.3s;
      }

      span {
        text-decoration: underline;
        transition: all 0.3s;

        &:hover {
          color: $primary-color;
          cursor: pointer;
        }
      }
    }
  }

  &__right {
    // background-image: url(../images/mainPhoto.png);
    background-size: cover;
    background-repeat: no-repeat;
    height: 45vw;
    width: 66.666%;
    z-index: 3;
  }

  &__text {
    position: absolute;
    left: 17%;
    top: 19vw;
    width: 62%;
    font-size: 65px;
    font-family: $menu-font;
    text-transform: uppercase;
    color: #fff;
    z-index: 4;
  }
}

.newScroll {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  top: 0px;
  width: 1250px;

  .mainPhotoHeader__left--scroll {
    justify-content: flex-start;
  }
}

@media screen and (max-width: 1650px) and (min-width: 1501px) {
  .mainPhotoHeader {

    &__text {
      top: 6vw;
    }
  }
  .newScroll {
    width: auto;
  }
}


@media screen and (max-width: 1500px){
  .mainPhotoHeader {

    &__text {
      font-size: 55px;

    }

    &__left {

      &--background {

        &--text {
          padding-top: 5vw;
          h2 {
            font-size: 32px;
            padding-top: 1vw;
          }

          h3 {
            font-size: 20px;
            padding-top: 1vw;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1300px) and (min-width: 1250px) {
  .mainPhotoHeader__left--background--text {
    font-size: 24px;
  }
}

@media screen and (max-width: 1200px){
  .mainPhotoHeader {

    &__text {
      font-size: 38px;
      top: 50px;
    }
  }
.newScroll {
  width: auto;
  padding-left: 15px;
  padding-right: 0px;
 }
}

@media screen and (max-width: 768px){
  .mainPhotoHeader {
    height: 400px;

    &__right {
      background-size: cover;
      height: 400px;
    }
    &__left {

      &--background {
        height: 300px;

        &--text {
          font-size: 24px;
          z-index: 10;
          position: absolute;
          padding: 15px;
          line-height: 41px;

          span {
            font-family: $fontSB;
          }
        }
      }

      &--scroll {
        display: none;
      }
    }
    &__text {
      left: 10%;
      font-size: 30px;
    }
  }
}

// single realizacje

@media screen and (max-width: 768px){
  .mainPhotoHeaderSingleRealization {
    flex-direction: column;

    .mainPhotoHeader__left{
      width: 100%;
      order: 2;
    }
    .mainPhotoHeader__right {
      width: 100%;
    }
    .mainPhotoHeader__left--background {
      height: auto;
      min-height: 265px;
    }
    .dots {
      display: none;
    }
  }

  .otherProjects__single {
    height: 150px;
  }
  .mainPhotoHeader {
    .swiper-pagination {
      display: none;
    }
  }
}

// portfolio 

@media screen and (max-width: 768px){
  .mainPhotoHeaderPortfolio {
    .mainPhotoHeader__left {
      display: none;
      
    }

    .mainPhotoHeader__right {
      width: 100%;
      height: 62vw;
    }
    .aboutUs__count {
      top: 30px;
    }
  }
}


// o nas 

@media screen and (max-width: 768px){
  .mainPhotoHeaderNas {
    .mainPhotoHeader__left {
      display: none;
      
    }

    .mainPhotoHeader__right {
      width: 100%;
      height: 68vw;
    }
  }
}

// usługi 

@media screen and (max-width: 768px){
  .mainPhotoHeaderUslugi {
    .mainPhotoHeader__left {
      display: none;
      
    }

    .mainPhotoHeader__right {
      width: 100%;
      height: 68vw;
    }
  }
}
