.swiper-container {
  width: 100%;
  height: 100%;
}
.aboutUs__swiper--slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.aboutUs {
  padding-top: 10.5vw;
  // padding-bottom: 90px;
  position: relative;
  padding-bottom: 2vw;

  &__swiper {
    height: 450px;
    // padding-bottom: 90px;

    &--pagination {
      width: 1250px !important;
      margin-left: auto;
      margin-right: auto;
      left: 0;
      right: 0;
      bottom: 90px !important;
      display: flex;
      
      .swiper-pagination-bullet {

        &:first-child {
          margin-left: 0px !important;
        }
      }
    }
  }

  .swiper-pagination-bullet {
    width: 18px;
    height: 4px;
    border-radius: 0px;
    margin: 0px 15px !important;
  }
  .swiper-pagination-bullet-active {
    background-color: $primary-color;
  }

  &__count {
    display: flex;
    position: absolute;
    left: 50px;
    bottom: 75px;
    z-index: 99999;
    font-family: $robotoB;
    font-size: 24px;

    &--active {
      color: $primary-color;
    }
  }
}

.aboutUs__content {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  text-align: left;

  &:hover {
    // cursor: pointer;
  }

  h3 {
    font-size: 36px;
    font-family: $fontSB;
    text-transform: uppercase;
    width: 500px;
    margin-bottom: 60px;
    height: 60px;
  }
  p {
    font-size: 14px;
    font-family: $fontL;
    width: 500px;
  }
}

.lineSlider {
  width: 300px;
  height: 2px;
  display: flex;
  position: absolute;
  top: 35%;
  z-index: 999;
  left: 90px;

  &__left {
    width: 210px;
    background-color: #ababab;
  }

  &__right {
    width: 90px;
    background-color: #fff;
  }
}

@media screen and (max-width: 1500px){
  .aboutUs{
    padding-top: 100px;

    &__count {
      bottom: 45px;
      left: 49.5px;
    }

    &__swiper {
      height: 390px;
    }
  }

  .lineSlider {
    left: 150px;
    &__left {
      width: 150px;
    }
  }
}

@media screen and (max-width: 768px){
  .visualisation-pagination {
    left: 15px;
  }
  .visualisation {
    height: 300px;
  }
  .aboutUs {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 50px;

    .mainTitle {
      margin-left: 0px;
    }

    &__swiper {
      height: 400px;

      &--pagination {
        width: auto !important;
      }
    }

    &__count {
      bottom: 50px;
      left: 15px;
    }

      &__content {

      h3 {
        width: 100%;
        margin-bottom: 20px;
        font-size: 24px;
      }

      p {
        width: 100%;
      }

      &--left {
        margin-bottom: 40px;
      }
    }
  } 
 }