.containerBlog {
  padding-top: 6vw;
  padding-left: 13.5%;

  h2 {
    margin-bottom: 50px;
  }

  a {
    width: 70%;
  }

  &__box {
    min-height: 460px;
    width: 100%;
    display: flex;
    // margin-bottom: 100px;

    &:nth-child(3) {
      flex-direction: row-reverse;

      .containerBlog__box--right .mainContainerPortfolio__box--left {
        align-items: flex-end;
        text-align: right;
      }
      .containerBlog__box--left {
        justify-content: flex-start;
        width: 45%;
      }
      .containerBlog__box--right {
        margin-right: 3%;
        margin-left: 0%;
        width: 52%;
      }
    }

    &--left {
      background-color: #f1f1f1;
      height: 100%;
      width: 52%;
      display: flex;
      justify-content: flex-end;

      &--photo {
        width: 569px;
        min-height: 460px;
        background-repeat: no-repeat;
      }
    }



    &--right {
      margin-left: 3%;
      width: 45%;

      .mainContainerPortfolio__box--left {
        background-color: #fff;
        width: auto;
        align-items: flex-start;  
      }

      .seeRealization {
        // margin-left: 100px;
        padding-bottom: 10px;

        a {
          transition: all 0.3s;
          font-family: $menu-font;

          &:hover {
            color: $primary-color;
          }
        }
      }
    }
  }
}

.aboutBlog {
  padding-top: 5vw;
  padding-bottom: 2vw;
}

@media screen and (max-width: 1500px){
  .containerBlog {

    a {
      width: 80%;
    }

    .mainContainerPortfolio__box--left {
      h3, p {
        padding: 50px 30px 0 30px;
      }
      .seeRealization {
        margin: 50px 30px 0px 30px;
      }
    }
  }
  .blog-box-left {
    
    .mainContainerPortfolio__box--left {
      padding: 10px 45px 0 45px;
    }
  }
}

@media screen and (max-width: 1200px){
  .containerBlog {
    padding: 0px 15px;
    justify-content: flex-end;

    .portfolioHover {
      height: 400px;
    }

    h2 {
      margin-bottom: 0px;
    }

    a {
      width: 100%;
    }

    &__box {
      flex-direction: column-reverse;
      min-height: auto;
      margin-bottom: 50px;

      &:nth-child(3) {
        flex-direction: column-reverse;

        .containerBlog__box--right {
          width: 100%;
        }
        .containerBlog__box--left {
          justify-content: flex-end;
          width: 100%;
        }
      }

      .mainContainerPortfolio__box--left {
        h3 {
          padding-left: 0px;
        }
        p {
          padding-left: 0px;
        }
        .seeRealization {
          margin-lefT: 0px;
        }
      }

      &--right {
        margin-left: 0%;
        width: 100%;
      }

      &--left {
        width: 100%;
        justify-content: flex-start;

        &--photo {
          height: 450px;
        }
      }
    }
  }
}

@media screen and (max-width: 768px){
  .containerBlog {

    .mainContainerPortfolio__box--left  {
      padding: 10px 0px 30px 0px;
      h3 {
        font-size: 24px;
        padding: 0px;
      }
      p {
        padding: 25px 0 0 0;
      }
      .seeRealization {
        margin-right: 0px;
      }
    }
  }
}