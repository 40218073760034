.pagination {
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
  margin-top: 30px;

  &__list {
    display: flex;
    font-size: 36px;
    font-family: $menu-font;

    li {
      margin: 0px 10px;
      width: 40px;
      height: 40px;
      transition: all 0.3s;

      a {
        float: left;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      &:hover {
        background-color: $primary-color;
        
        a {
          color: #fff;
        }
      }
    }

    &--active {
      background-color: $primary-color;

      a {
        color: #fff;
      }
    }
  }
}

@media screen and (max-width: 768px){
  .pagination__list {
    font-size: 24px;

    li {
      width: 30px;
      height: 30px;
      margin: 0 5px;
    }
  }
}