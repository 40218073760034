.mainPhoto {
  // background-image: url(../images/tlo.png);
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  height: 33vw;
}

@media screen and (max-width: 768px){
  .mainPhoto {
    height: 300px;
    background-position: center;
    background-size: cover;
  }
}