.singleRealizacja-photo {
  height: 673px;
  width: 1010px;
  background-size: cover;
  background-repeat: no-repeat;
  margin-bottom: 75px;
}

.otherProjects {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  height: 450px;
  margin-top: 100px;
  margin-bottom: 100px;

  &__single {
    // background-image: url(../images/proponowane.png);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 379px;
    height: 447px;
    margin-bottom: 20px;
  }
}

// @media screen and (max-width: 1500px){
//   .singleRealizacja {
//     margin-right: 25px;
//   }
// }

@media screen and (max-width: 1300px){
  .singleRealizacja-photo {
    width: 900px;
  }
  .otherProjects {
    margin-bottom: 0px;
    margin-top: 50px;
    height: auto;
    padding-right: 15px;

    a {
      width: 30%;
    }

    &__single {
      width: 100%;
      height: 342px;
    }
  }
}

@media screen and (max-width: 1200px ){
  .singleRealizacja-photo {
    height: 480px;
  }
}

@media screen and (max-width: 768px){
  .singleRealizacja-photo {
    width: auto;
    background-size: cover;
    height: 180px;
    margin-bottom: 20px;
  }
  .otherProjects {
    justify-content: center;
    height: auto;
    flex-direction: column;

    a {
      width: 100%;
    }
  }
}