@font-face {
  font-family: Montserrat-Bold;
  src: url(../fonts/Montserrat-Bold.ttf);
}

@font-face {
  font-family: Montserrat-SemiBold;
  src: url(../fonts/Montserrat-SemiBold.ttf);
}

@font-face {
  font-family: Montserrat-Light;
  src: url(../fonts/Montserrat-Light.ttf);
}

@font-face {
  font-family: Montserrat-Medium;
  src: url(../fonts/Montserrat-Medium.ttf);
}

@font-face {
  font-family: Montserrat-Regular;
  src: url(../fonts/Montserrat-Regular.ttf);
}

@font-face {
  font-family: Roboto-Bold;
  src: url(../fonts/Roboto-Bold.ttf);
}

/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
main, menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, main, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
  width: 100%;
  overflow-x: hidden;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

a {
  text-decoration: none;
  color: #000;
}

a:focus {
  text-decoration: none;
  outline: none;
}

p {
  line-height: 24px;
}

textarea {
  resize: none;
}

.swiper-container {
  width: 100%;
  height: 100%;
}

.aboutUs__swiper--slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.aboutUs {
  padding-top: 10.5vw;
  position: relative;
  padding-bottom: 2vw;
}

.aboutUs__swiper {
  height: 450px;
}

.aboutUs__swiper--pagination {
  width: 1250px !important;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  bottom: 90px !important;
  display: flex;
}

.aboutUs__swiper--pagination .swiper-pagination-bullet:first-child {
  margin-left: 0px !important;
}

.aboutUs .swiper-pagination-bullet {
  width: 18px;
  height: 4px;
  border-radius: 0px;
  margin: 0px 15px !important;
}

.aboutUs .swiper-pagination-bullet-active {
  background-color: #4ad681;
}

.aboutUs__count {
  display: flex;
  position: absolute;
  left: 50px;
  bottom: 75px;
  z-index: 99999;
  font-family: "Roboto-Bold", sans-serif;
  font-size: 24px;
}

.aboutUs__count--active {
  color: #4ad681;
}

.aboutUs__content {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  text-align: left;
}

.aboutUs__content h3 {
  font-size: 36px;
  font-family: "Montserrat-SemiBold", sans-serif;
  text-transform: uppercase;
  width: 500px;
  margin-bottom: 60px;
  height: 60px;
}

.aboutUs__content p {
  font-size: 14px;
  font-family: "Montserrat-Light", sans-serif;
  width: 500px;
}

.lineSlider {
  width: 300px;
  height: 2px;
  display: flex;
  position: absolute;
  top: 35%;
  z-index: 999;
  left: 90px;
}

.lineSlider__left {
  width: 210px;
  background-color: #ababab;
}

.lineSlider__right {
  width: 90px;
  background-color: #fff;
}

@media screen and (max-width: 1500px) {
  .aboutUs {
    padding-top: 100px;
  }
  .aboutUs__count {
    bottom: 45px;
    left: 49.5px;
  }
  .aboutUs__swiper {
    height: 390px;
  }
  .lineSlider {
    left: 150px;
  }
  .lineSlider__left {
    width: 150px;
  }
}

@media screen and (max-width: 768px) {
  .visualisation-pagination {
    left: 15px;
  }
  .visualisation {
    height: 300px;
  }
  .aboutUs {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 50px;
  }
  .aboutUs .mainTitle {
    margin-left: 0px;
  }
  .aboutUs__swiper {
    height: 400px;
  }
  .aboutUs__swiper--pagination {
    width: auto !important;
  }
  .aboutUs__count {
    bottom: 50px;
    left: 15px;
  }
  .aboutUs__content h3 {
    width: 100%;
    margin-bottom: 20px;
    font-size: 24px;
  }
  .aboutUs__content p {
    width: 100%;
  }
  .aboutUs__content--left {
    margin-bottom: 40px;
  }
}

.mainContainer {
  width: 1250px;
  margin: 0 auto;
}

.mainContainerPortfolio {
  width: 1600px;
  margin: 0 auto;
}

@media screen and (max-width: 1251px) {
  .mainContainer {
    width: 100%;
  }
  .mainContainerPortfolio {
    width: 100%;
    margin: 0 auto;
  }
}

.mainTitle {
  font-size: 36px;
  font-family: "Montserrat-Bold", sans-serif;
  width: 1250px;
  margin: 0 auto;
  position: relative;
  padding-bottom: 30px;
}

.mainTitle:after {
  content: ' ';
  position: absolute;
  bottom: 0px;
  left: 0px;
  height: 1px;
  background-color: #000;
  width: 235px;
}

.normalHeader {
  font-size: 36px;
  font-family: "Montserrat-Bold", sans-serif;
  text-transform: uppercase;
}

.exampleText {
  font-family: "Montserrat-Light", sans-serif;
  font-size: 16px;
  margin-top: 20px;
  margin-bottom: 40px;
}

@media screen and (max-width: 1251px) {
  .mainTitle {
    width: 100%;
    margin-left: 15px;
  }
  .mainTitle:after {
    left: 0px;
  }
}

@media screen and (max-width: 768px) {
  .mainTitle {
    font-size: 24px;
  }
  .normalHeader {
    font-size: 24px;
  }
}

.dots {
  background-image: url(../images/kropki-new.png);
  background-position: center;
}

.containerOnas {
  padding-left: 32.8%;
  width: auto;
}

.containerOnas h3 {
  font-size: 36px;
  font-family: "Montserrat-SemiBold", sans-serif;
  width: 850px;
  margin-bottom: 50px;
}

.containerOnas p {
  width: 850px;
  font-family: "Montserrat-Light", sans-serif;
  padding-bottom: 45px;
  line-height: 32px;
}

.containerOnas__seeMore {
  position: relative !important;
  bottom: auto !important;
  left: auto !important;
  padding-bottom: 12vw;
}

.containerOnasGrey {
  background: #f1f1f1;
  padding-top: 20px;
  min-height: 37vw;
  padding-left: 0%;
  padding-bottom: 30px;
  width: auto;
}

.containerOnasGrey p {
  padding-left: 32.7%;
}

.containerOnasGrey__count {
  display: flex;
  justify-content: space-around;
  margin-top: 6vw;
  width: 1250px;
  margin-left: auto;
  margin-right: auto;
}

.containerOnasGrey__count--single {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: "Montserrat-Regular", sans-serif;
}

.containerOnasGrey__count--single h4 {
  font-size: 64px;
  margin-bottom: 10px;
}

.containerOnasGrey__count--single h5 {
  font-size: 18px;
  text-transform: uppercase;
}

.countM2 {
  display: flex;
  align-items: flex-end;
}

.countM2 h6 {
  font-size: 36px;
  margin-left: 10px;
  margin-bottom: 15px;
}

@media screen and (max-width: 1650px) and (min-width: 1501px) {
  .containerOnasGrey {
    padding-top: 50px;
  }
}

@media screen and (max-width: 1500px) {
  .containerOnas {
    padding-left: 25.8%;
  }
  .containerOnasGrey {
    padding-left: 0%;
  }
  .containerOnasGrey p {
    padding-left: 25.8%;
  }
}

@media screen and (max-width: 1380px) and (min-width: 1200px) {
  .containerOnasGrey__count {
    margin-top: 20px;
  }
}

@media screen and (max-width: 1200px) {
  .containerOnas {
    padding: 15px 15px;
  }
  .containerOnas h3 {
    width: auto;
  }
  .containerOnas p {
    width: auto;
  }
  .containerOnasGrey p {
    padding-left: 0px;
  }
  .containerOnasGrey__count {
    width: auto;
  }
  .containerOnasGrey__count--single h4 {
    font-size: 48px;
  }
  .containerOnasGrey__count--single h5 {
    font-size: 14px;
  }
}

@media screen and (max-width: 768px) {
  .containerOnas {
    padding: 15px 0px;
  }
  .containerOnas__seeMore {
    display: block !important;
  }
  .containerOnas p {
    padding-bottom: 20px;
  }
  .containerOnasGrey__count {
    flex-direction: column;
  }
  .containerOnasGrey__count--single {
    margin-bottom: 30px;
  }
}

.mainContainerPortfolio {
  width: 1600px;
  margin: 0 auto;
  padding-top: 8vw;
}

.mainContainerPortfolio ul {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 80px;
}

.mainContainerPortfolio ul li {
  text-transform: uppercase;
  margin-right: 60px;
  font-size: 36px;
  transition: all 0.3s;
  font-family: "Montserrat-Bold", sans-serif;
  margin-bottom: 20px;
}

.mainContainerPortfolio ul li:hover {
  color: #4ad681;
  cursor: pointer;
}

.swiperPortfolio {
  position: inherit;
}

.swiperPortfolio .swiper-pagination-portfolio {
  top: 80px;
  left: 17%;
  width: 200px;
}

.swiperPortfolio .aboutUs__count {
  top: 80px;
  left: 5%;
}

.swiperPortfolio .aboutUs__count .aboutUs__count--full, .swiperPortfolio .aboutUs__count .aboutUs-slash {
  color: #fff;
}

.swiperPortfolio .swiper-pagination-bullet {
  width: 18px;
  height: 4px;
  border-radius: 0px;
  margin: 0px 15px !important;
  background-color: #fff;
}

.swiperPortfolio .swiper-pagination-bullet:first-child {
  margin-left: 0px !important;
}

.swiperPortfolio .swiper-pagination-bullet-active {
  background-color: #4ad681;
}

.swiperPortfolio .slidePort {
  background-repeat: no-repeat;
  background-size: cover;
}

@media screen and (max-width: 1601px) {
  .mainContainerPortfolio {
    width: 100%;
  }
  .mainContainerPortfolio ul {
    padding-left: 65px;
  }
  .mainContainerPortfolio ul li {
    font-size: 24px;
  }
}

@media screen and (max-width: 1200px) {
  .swiperPortfolio {
    position: inherit;
  }
  .swiperPortfolio .swiper-pagination-portfolio {
    top: 280px;
    left: 11%;
    width: 200px;
  }
  .swiperPortfolio .aboutUs__count {
    top: 280px;
    left: 5%;
  }
}

@media screen and (max-width: 768px) {
  .mainContainerPortfolio ul {
    flex-direction: column;
    padding-left: 15px;
    margin-bottom: 30px;
  }
  .swiperPortfolio {
    position: inherit;
  }
  .swiperPortfolio .swiper-pagination-portfolio {
    top: 215px;
    left: 4%;
    width: 123px;
  }
  .swiperPortfolio .swiper-pagination-bullet {
    margin: 0 5px !important;
  }
  .swiperPortfolio .aboutUs__count {
    top: 240px;
    left: 5%;
  }
}

.singleRealizacja-photo {
  height: 673px;
  width: 1010px;
  background-size: cover;
  background-repeat: no-repeat;
  margin-bottom: 75px;
}

.otherProjects {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  height: 450px;
  margin-top: 100px;
  margin-bottom: 100px;
}

.otherProjects__single {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 379px;
  height: 447px;
  margin-bottom: 20px;
}

@media screen and (max-width: 1300px) {
  .singleRealizacja-photo {
    width: 900px;
  }
  .otherProjects {
    margin-bottom: 0px;
    margin-top: 50px;
    height: auto;
    padding-right: 15px;
  }
  .otherProjects a {
    width: 30%;
  }
  .otherProjects__single {
    width: 100%;
    height: 342px;
  }
}

@media screen and (max-width: 1200px) {
  .singleRealizacja-photo {
    height: 480px;
  }
}

@media screen and (max-width: 768px) {
  .singleRealizacja-photo {
    width: auto;
    background-size: cover;
    height: 180px;
    margin-bottom: 20px;
  }
  .otherProjects {
    justify-content: center;
    height: auto;
    flex-direction: column;
  }
  .otherProjects a {
    width: 100%;
  }
}

.containerBlog {
  padding-top: 6vw;
  padding-left: 13.5%;
}

.containerBlog h2 {
  margin-bottom: 50px;
}

.containerBlog a {
  width: 70%;
}

.containerBlog__box {
  min-height: 460px;
  width: 100%;
  display: flex;
}

.containerBlog__box:nth-child(3) {
  flex-direction: row-reverse;
}

.containerBlog__box:nth-child(3) .containerBlog__box--right .mainContainerPortfolio__box--left {
  align-items: flex-end;
  text-align: right;
}

.containerBlog__box:nth-child(3) .containerBlog__box--left {
  justify-content: flex-start;
  width: 45%;
}

.containerBlog__box:nth-child(3) .containerBlog__box--right {
  margin-right: 3%;
  margin-left: 0%;
  width: 52%;
}

.containerBlog__box--left {
  background-color: #f1f1f1;
  height: 100%;
  width: 52%;
  display: flex;
  justify-content: flex-end;
}

.containerBlog__box--left--photo {
  width: 569px;
  min-height: 460px;
  background-repeat: no-repeat;
}

.containerBlog__box--right {
  margin-left: 3%;
  width: 45%;
}

.containerBlog__box--right .mainContainerPortfolio__box--left {
  background-color: #fff;
  width: auto;
  align-items: flex-start;
}

.containerBlog__box--right .seeRealization {
  padding-bottom: 10px;
}

.containerBlog__box--right .seeRealization a {
  transition: all 0.3s;
  font-family: "Montserrat-Bold", sans-serif;
}

.containerBlog__box--right .seeRealization a:hover {
  color: #4ad681;
}

.aboutBlog {
  padding-top: 5vw;
  padding-bottom: 2vw;
}

@media screen and (max-width: 1500px) {
  .containerBlog a {
    width: 80%;
  }
  .containerBlog .mainContainerPortfolio__box--left h3, .containerBlog .mainContainerPortfolio__box--left p {
    padding: 50px 30px 0 30px;
  }
  .containerBlog .mainContainerPortfolio__box--left .seeRealization {
    margin: 50px 30px 0px 30px;
  }
  .blog-box-left .mainContainerPortfolio__box--left {
    padding: 10px 45px 0 45px;
  }
}

@media screen and (max-width: 1200px) {
  .containerBlog {
    padding: 0px 15px;
    justify-content: flex-end;
  }
  .containerBlog .portfolioHover {
    height: 400px;
  }
  .containerBlog h2 {
    margin-bottom: 0px;
  }
  .containerBlog a {
    width: 100%;
  }
  .containerBlog__box {
    flex-direction: column-reverse;
    min-height: auto;
    margin-bottom: 50px;
  }
  .containerBlog__box:nth-child(3) {
    flex-direction: column-reverse;
  }
  .containerBlog__box:nth-child(3) .containerBlog__box--right {
    width: 100%;
  }
  .containerBlog__box:nth-child(3) .containerBlog__box--left {
    justify-content: flex-end;
    width: 100%;
  }
  .containerBlog__box .mainContainerPortfolio__box--left h3 {
    padding-left: 0px;
  }
  .containerBlog__box .mainContainerPortfolio__box--left p {
    padding-left: 0px;
  }
  .containerBlog__box .mainContainerPortfolio__box--left .seeRealization {
    margin-lefT: 0px;
  }
  .containerBlog__box--right {
    margin-left: 0%;
    width: 100%;
  }
  .containerBlog__box--left {
    width: 100%;
    justify-content: flex-start;
  }
  .containerBlog__box--left--photo {
    height: 450px;
  }
}

@media screen and (max-width: 768px) {
  .containerBlog .mainContainerPortfolio__box--left {
    padding: 10px 0px 30px 0px;
  }
  .containerBlog .mainContainerPortfolio__box--left h3 {
    font-size: 24px;
    padding: 0px;
  }
  .containerBlog .mainContainerPortfolio__box--left p {
    padding: 25px 0 0 0;
  }
  .containerBlog .mainContainerPortfolio__box--left .seeRealization {
    margin-right: 0px;
  }
}

.top-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 95px 30px 35px;
  font-family: "Montserrat-Bold", sans-serif;
  position: relative;
  transition: all 0.3s;
}

.top-bar__left .site-logo {
  background-image: url(../images/logo.svg);
  background-size: contain;
  background-repeat: no-repeat;
  width: 180px;
  height: 35px;
}

.top-bar__right {
  display: flex;
}

.top-bar__right--list {
  display: flex;
  margin-right: 45px;
  visibility: hidden;
}

.top-bar__right--list li {
  padding: 0px 15px;
}

.top-bar__right--list li a {
  color: #3a3a3a;
  transition: all 0.3s;
}

.top-bar__right--list li a:hover {
  color: #4ad681;
}

.top-bar__right--social {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-left: 25px;
  width: 95px;
  border-left: 1px solid #747474;
  position: absolute;
  top: 0px;
  right: 0px;
  height: 95px;
  transition: all 0.3s;
}

.top-bar__right--social a {
  width: 25px;
  height: 25px;
}

.top-bar__right--social a svg path {
  transition: all 0.3s;
}

.top-bar__right--social a svg:hover path {
  fill: #4ad681;
}

.menu__list {
  display: flex;
  justify-content: space-between;
  width: 700px;
}

.menu__list li a {
  color: #3a3a3a;
  text-transform: uppercase;
  font-size: 16px;
  transition: all 0.3s;
}

.menu__list li a:hover {
  color: #4ad681;
}

.menu__element--active {
  color: #4ad681 !important;
}

.mainMenuActive {
  position: fixed;
  z-index: 99999999999;
}

.mainMenu {
  transition: all 0.3s;
  width: 100%;
  top: 0;
  background-color: #fff;
}

.top-barActive {
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #747474;
}

.social-active {
  height: 75px;
}

@media screen and (max-width: 1250px) {
  .menu__list {
    width: 500px;
  }
}

@media screen and (max-width: 1000px) {
  .top-bar {
    padding: 40px 95px 40px 15px;
  }
  .top-bar__right--social {
    padding-left: 15px;
    padding-right: 15px;
    width: 60px;
    height: 115px;
  }
  .top-bar__right--list {
    flex-direction: column;
    align-items: center;
    margin-right: 5px;
  }
  .top-bar__right--list li {
    padding: 0px 5px;
  }
  .menu__list {
    width: 400px;
  }
}

@media screen and (max-width: 768px) {
  .mainMenu {
    display: none;
    transition: unset;
  }
  .top-bar {
    display: flex;
    flex-direction: column;
    padding: 15px 0px;
  }
  .top-bar__right {
    flex-direction: column;
  }
  .top-bar__right--social {
    position: relative;
    height: 40px;
    border-left: 0px;
  }
  .top-bar__right--list {
    margin: 0px 0px 10px 0px;
    display: none;
  }
  .top-bar__right--list li {
    margin: 5px 0px;
  }
  .menu__list {
    flex-direction: column;
    align-items: center;
    margin: 15px 0px;
  }
  .menu__list li {
    margin: 5px 0px;
  }
}

.hamburger {
  display: none;
  z-index: 9999;
}

#nav-icon1 {
  width: 45px;
  height: 45px;
  position: relative;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;
}

#nav-icon1 span {
  display: block;
  position: absolute;
  height: 7px;
  width: 100%;
  background: #4ad681;
  border-radius: 9px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .25s ease-in-out;
  -moz-transition: .25s ease-in-out;
  -o-transition: .25s ease-in-out;
  transition: .25s ease-in-out;
}

#nav-icon1 span:nth-child(1) {
  top: 0px;
}

#nav-icon1 span:nth-child(2) {
  top: 13px;
}

#nav-icon1 span:nth-child(3) {
  top: 26px;
}

#nav-icon1.open span:nth-child(1) {
  top: 18px;
  -webkit-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  -o-transform: rotate(135deg);
  transform: rotate(135deg);
}

#nav-icon1.open span:nth-child(2) {
  opacity: 0;
  left: -60px;
}

#nav-icon1.open span:nth-child(3) {
  top: 18px;
  -webkit-transform: rotate(-135deg);
  -moz-transform: rotate(-135deg);
  -o-transform: rotate(-135deg);
  transform: rotate(-135deg);
}

@media screen and (max-width: 768px) {
  .hamburger {
    display: block;
    position: absolute;
    top: 20px;
    right: 20px;
  }
}

.mainPhoto {
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  height: 33vw;
}

@media screen and (max-width: 768px) {
  .mainPhoto {
    height: 300px;
    background-position: center;
    background-size: cover;
  }
}

.visualisation {
  height: 63vw;
  width: 100%;
  position: relative;
}

.visualisation .swiper-slide {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: 90px;
}

.visualisation .swiper-pagination-bullet {
  width: 18px;
  height: 4px;
  border-radius: 0px;
  margin: 0px 15px !important;
}

.visualisation .swiper-pagination-bullet-active {
  background-color: #4ad681;
}

.visualisation .visualisation-pagination {
  top: 5px;
}

.visualisation .aboutUs__count {
  top: 0px;
}

@media screen and (max-width: 1500px) {
  .visualisation .aboutUs__count {
    top: 25px;
    width: 1250px;
    margin-left: auto;
    margin-right: auto;
    right: 0;
    left: 0;
  }
}

.howWeWork {
  padding: 90px 0px 0px 0px;
}

.howWeWork__content {
  margin: 100px 0px 0px 0px;
}

.howWeWork__content .aboutUs__content--single {
  margin-bottom: 150px;
  z-index: 1;
}

.howWeWork h3 {
  position: relative;
  padding-bottom: 30px;
}

.howWeWork h3:after {
  content: ' ';
  position: absolute;
  bottom: 0px;
  left: 0px;
  height: 1px;
  background-color: #000;
  width: 235px;
}

.greyBoxContent {
  position: relative;
}

.greyBox {
  height: 350px;
  width: 100%;
  background-color: #f1f1f1;
  position: absolute;
  top: 21.2%;
}

@media screen and (max-width: 1650px) and (min-width: 1501px) {
  .greyBox {
    top: 21%;
  }
}

@media screen and (max-width: 1500px) {
  .howWeWork__content .aboutUs__content--single {
    margin-bottom: 100px;
  }
  .howWeWork .mainTitle {
    width: auto;
    margin-left: 0px;
  }
  .greyBox {
    top: 20.5%;
  }
}

@media screen and (max-width: 1300px) and (min-width: 1250px) {
  .howWeWork .mainTitle {
    margin-left: 50px;
  }
}

@media screen and (max-width: 1200px) {
  .greyBox {
    display: none;
  }
}

@media screen and (max-width: 1024px) {
  .visualisation .aboutUs__count {
    width: 300px;
    right: auto;
    left: 15px;
  }
}

@media screen and (max-width: 768px) {
  .visualisation {
    height: 300px;
  }
  .visualisation .visualisation-pagination {
    left: 15px;
  }
  .howWeWork {
    padding-top: 40px;
    padding-left: 15px;
    padding-right: 15px;
  }
  .howWeWork .mainTitle {
    margin-left: 0px;
  }
  .howWeWork h3 {
    padding-bottom: 0px;
  }
  .howWeWork__content {
    margin-top: 50px;
  }
  .howWeWork__content .aboutUs__content--single {
    margin-bottom: 60px;
  }
}

.lastRealizationContent {
  background-color: #f1f1f1;
  padding-top: 50px;
  position: relative;
}

.lastRealization {
  height: 1px;
  min-height: 1000px;
  position: relative;
  display: flex;
  justify-content: flex-end;
}

.lastRealization__header {
  position: absolute;
  left: 0px;
  top: 125px;
  font-family: "Montserrat-SemiBold", sans-serif;
  font-size: 36px;
}

.lastRealization__header h3 {
  font-family: "Montserrat-Bold", sans-serif;
}

.lastRealization__seeMore {
  position: absolute;
  bottom: 130px;
  left: 299px;
}

.lastRealization__seeMore a {
  font-size: 16px;
  font-family: "Roboto-Bold", sans-serif;
  padding-bottom: 15px;
  position: relative;
  transition: all 0.3s;
}

.lastRealization__seeMore a:hover {
  color: #4ad681;
}

.lastRealization__seeMore a:after {
  content: ' ';
  height: 4px;
  width: 280px;
  background-color: #4ad681;
  position: absolute;
  bottom: 0px;
  left: 0px;
}

.lastRealization__count {
  bottom: auto;
  top: 39px;
}

.realizationsSwiper {
  display: flex;
  justify-content: flex-end;
  width: 951px;
  margin: initial;
  position: initial;
}

.realizationsSwiper h3 {
  font-size: 36px;
  font-family: "Montserrat-SemiBold", sans-serif;
  margin-bottom: 65px;
  margin-top: 45px;
}

.realizationsSwiper p {
  font-size: 14px;
  font-family: "Montserrat-Light", sans-serif;
  margin-bottom: 30px;
}

.realizationsSwiper .swiper-pagination-bullet {
  width: 18px;
  height: 4px;
  border-radius: 0px;
  margin: 0px 10px !important;
}

.realizationsSwiper .swiper-pagination-bullet:hover {
  cursor: pointer;
}

.realizationsSwiper .swiper-pagination-bullet:first-child {
  margin-left: 0px !important;
}

.realizationsSwiper .realizationsPagination {
  position: absolute;
  left: 0px;
  top: -10px;
  width: auto;
}

.realizationsSwiper .swiper-button-next, .realizationsSwiper .swiper-button-prev {
  top: 35.8%;
  background-size: contain;
  width: 14px;
  height: 27px;
}

.realizationsSwiper .swiper-button-next {
  right: auto;
  left: 35px;
}

.realizationsSwiper .swiper-button-prev {
  left: 0px;
}

.realizationsSwiper .swiper-pagination-bullet-active {
  background-color: #4ad681;
}

.realizationsSwiper .swiper-button-prev {
  background-image: url(../images/swiper-prev.svg);
}

.realizationsSwiper .swiper-button-next {
  background-image: url(../images/swiper-next.svg);
}

.realizationsSwiper .swiper-slider-realizationsPhoto {
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 507px;
}

.realizationsSwiper .swiper-wrapper {
  width: 951px;
  height: 507px;
}

@media screen and (max-width: 1500px) {
  .lastRealization__count {
    top: 75px;
    left: 49px;
  }
}

@media screen and (max-width: 1300px) {
  .lineSlider {
    display: none;
  }
  .lastRealization {
    justify-content: center;
  }
  .lastRealization__seeMore {
    left: 15px;
    bottom: 35px;
  }
  .lastRealization__header {
    left: 220px;
    top: 0;
  }
  .lastRealization__count {
    left: 15px;
  }
  .realizationsSwiper {
    width: 100%;
    padding-top: 100px;
  }
  .realizationsSwiper .swiper-wrapper {
    width: 100%;
  }
  .realizationsSwiper .swiper-slider-realizationsPhoto {
    background-size: contain;
  }
  .realizationsSwiper .realizationsPagination {
    left: 15px;
  }
  .realizationsSwiper .swiper-button-next {
    top: 5%;
    right: 50px;
    left: auto;
  }
  .realizationsSwiper .swiper-button-prev {
    top: 5%;
    left: auto;
    right: 110px;
  }
}

@media screen and (max-width: 768px) {
  .realizationsSwiper {
    padding-top: 170px;
  }
  .realizationsSwiper h3 {
    padding: 0 15px;
  }
  .realizationsSwiper p {
    padding: 0 15px;
  }
  .realizationsSwiper .swiper-slider-realizationsPhoto {
    margin-left: 15px;
  }
  .realizationsSwiper .swiper-wrapper {
    height: auto;
  }
  .lastRealization {
    height: auto;
    min-height: auto;
  }
  .lastRealization__header {
    left: 15px;
    top: 70px;
  }
}

.footerContainer {
  position: relative;
  height: 9vw;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Roboto-Bold", sans-serif;
}

.footerContainer:after {
  content: " ";
  height: 1px;
  position: absolute;
  width: 100%;
  left: 0px;
  background-color: #4ad681;
}

.footerContainer__logo {
  color: #747474;
  position: absolute;
  right: 50px;
  bottom: 1.4vw;
}

.footerContainer__logo a {
  color: #747474;
}

.footerContainer__logo a p {
  color: #747474;
}

.list-footer {
  background-color: #fff;
  color: #747474;
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 630px;
  z-index: 10;
}

.list-footer a {
  color: #747474;
  transition: all 0.3s;
}

.list-footer a:hover {
  color: #4ad681;
}

@media screen and (max-width: 1250px) {
  .footerContainer {
    height: 135px;
  }
}

@media screen and (max-width: 768px) {
  .footerContainer {
    flex-direction: column;
    min-height: 200px;
  }
  .footerContainer:after {
    content: none;
  }
  .footerContainer__logo {
    position: relative;
    margin: 0 auto;
    right: auto;
    bottom: auto;
  }
  .list-footer {
    padding: 15px 0px;
    flex-direction: column;
    width: 100%;
  }
  .list-footer li {
    margin: 5px 0px;
  }
}

.mainMenu {
  position: fixed;
  z-index: 9999999;
}

.mainPhotoHeader {
  display: flex;
  height: 45vw;
  transition: all 0.3s;
  position: relative;
}

.mainPhotoHeader__white {
  width: 100%;
  height: 8vw;
  position: absolute;
  bottom: 0px;
}

.mainPhotoHeader__left {
  width: 33.333%;
}

.mainPhotoHeader__left--background {
  background-color: #000;
  height: 37vw;
  text-transform: uppercase;
}

.mainPhotoHeader__left--background--text {
  color: #fff;
  font-size: 36px;
  padding: 8vw 40px 0px 40px;
  line-height: 46px;
}

.mainPhotoHeader__left--background--text h2 {
  font-family: "Montserrat-Bold", sans-serif;
  text-transform: uppercase;
  padding-bottom: 3.5vw;
  min-width: 250px;
  position: relative;
}

.mainPhotoHeader__left--background--text h2:after {
  content: ' ';
  height: 1px;
  width: 240px;
  background-color: #fff;
  position: absolute;
  bottom: 15px;
  left: 0px;
}

.mainPhotoHeader__left--background--text h3 {
  padding-top: 3.5vw;
  font-family: "Montserrat-Bold", sans-serif;
  text-transform: uppercase;
  font-size: 28px;
}

.mainPhotoHeader__left--background--text h4 {
  font-family: "Montserrat-Light", sans-serif;
}

.mainPhotoHeader__left--background--text h4 span {
  font-family: "Montserrat-SemiBold", sans-serif;
}

.mainPhotoHeader__left--scroll {
  height: 8vw;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 30px;
  font-family: "Montserrat-Bold", sans-serif;
}

.mainPhotoHeader__left--scroll p {
  font-size: 14px;
  z-index: 2;
}

.mainPhotoHeader__left--scroll--arrow {
  width: 12px;
  height: 15px;
  background-image: url(../images/arrow.svg);
  background-position: center;
  background-size: contain;
  margin-right: 15px;
  margin-bottom: 15px;
  transition: all 0.3s;
}

.mainPhotoHeader__left--scroll span {
  text-decoration: underline;
  transition: all 0.3s;
}

.mainPhotoHeader__left--scroll span:hover {
  color: #4ad681;
  cursor: pointer;
}

.mainPhotoHeader__right {
  background-size: cover;
  background-repeat: no-repeat;
  height: 45vw;
  width: 66.666%;
  z-index: 3;
}

.mainPhotoHeader__text {
  position: absolute;
  left: 17%;
  top: 19vw;
  width: 62%;
  font-size: 65px;
  font-family: "Montserrat-Bold", sans-serif;
  text-transform: uppercase;
  color: #fff;
  z-index: 4;
}

.newScroll {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  top: 0px;
  width: 1250px;
}

.newScroll .mainPhotoHeader__left--scroll {
  justify-content: flex-start;
}

@media screen and (max-width: 1650px) and (min-width: 1501px) {
  .mainPhotoHeader__text {
    top: 6vw;
  }
  .newScroll {
    width: auto;
  }
}

@media screen and (max-width: 1500px) {
  .mainPhotoHeader__text {
    font-size: 55px;
  }
  .mainPhotoHeader__left--background--text {
    padding-top: 5vw;
  }
  .mainPhotoHeader__left--background--text h2 {
    font-size: 32px;
    padding-top: 1vw;
  }
  .mainPhotoHeader__left--background--text h3 {
    font-size: 20px;
    padding-top: 1vw;
  }
}

@media screen and (max-width: 1300px) and (min-width: 1250px) {
  .mainPhotoHeader__left--background--text {
    font-size: 24px;
  }
}

@media screen and (max-width: 1200px) {
  .mainPhotoHeader__text {
    font-size: 38px;
    top: 50px;
  }
  .newScroll {
    width: auto;
    padding-left: 15px;
    padding-right: 0px;
  }
}

@media screen and (max-width: 768px) {
  .mainPhotoHeader {
    height: 400px;
  }
  .mainPhotoHeader__right {
    background-size: cover;
    height: 400px;
  }
  .mainPhotoHeader__left--background {
    height: 300px;
  }
  .mainPhotoHeader__left--background--text {
    font-size: 24px;
    z-index: 10;
    position: absolute;
    padding: 15px;
    line-height: 41px;
  }
  .mainPhotoHeader__left--background--text span {
    font-family: "Montserrat-SemiBold", sans-serif;
  }
  .mainPhotoHeader__left--scroll {
    display: none;
  }
  .mainPhotoHeader__text {
    left: 10%;
    font-size: 30px;
  }
}

@media screen and (max-width: 768px) {
  .mainPhotoHeaderSingleRealization {
    flex-direction: column;
  }
  .mainPhotoHeaderSingleRealization .mainPhotoHeader__left {
    width: 100%;
    order: 2;
  }
  .mainPhotoHeaderSingleRealization .mainPhotoHeader__right {
    width: 100%;
  }
  .mainPhotoHeaderSingleRealization .mainPhotoHeader__left--background {
    height: auto;
    min-height: 265px;
  }
  .mainPhotoHeaderSingleRealization .dots {
    display: none;
  }
  .otherProjects__single {
    height: 150px;
  }
  .mainPhotoHeader .swiper-pagination {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .mainPhotoHeaderPortfolio .mainPhotoHeader__left {
    display: none;
  }
  .mainPhotoHeaderPortfolio .mainPhotoHeader__right {
    width: 100%;
    height: 62vw;
  }
  .mainPhotoHeaderPortfolio .aboutUs__count {
    top: 30px;
  }
}

@media screen and (max-width: 768px) {
  .mainPhotoHeaderNas .mainPhotoHeader__left {
    display: none;
  }
  .mainPhotoHeaderNas .mainPhotoHeader__right {
    width: 100%;
    height: 68vw;
  }
}

@media screen and (max-width: 768px) {
  .mainPhotoHeaderUslugi .mainPhotoHeader__left {
    display: none;
  }
  .mainPhotoHeaderUslugi .mainPhotoHeader__right {
    width: 100%;
    height: 68vw;
  }
}

.mainContainerPortfolio__box {
  width: 100%;
  min-height: 530px;
  display: flex;
  text-align: right;
  margin-bottom: 28px;
}

.mainContainerPortfolio__box a {
  width: 100%;
}

.mainContainerPortfolio__box--left {
  width: 400px;
  min-height: 530px;
  background-color: #f2f2f2;
  flex-shrink: 0;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  padding: 90px 100px 0px 100px;
}

.mainContainerPortfolio__box--left h3 {
  font-family: "Montserrat-Bold", sans-serif;
  font-size: 36px;
  text-transform: uppercase;
  width: 100%;
}

.mainContainerPortfolio__box--left p {
  font-family: "Montserrat-Regular", sans-serif;
  font-size: 14px;
  width: 100%;
  padding-top: 60px;
  padding-bottom: 20px;
}

.mainContainerPortfolio__box--right {
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 100%;
  width: 100%;
}

.seeRealization {
  font-size: 16px;
  width: 175px;
  padding-bottom: 10px;
  border-bottom: 5px solid #4ad681;
}

.seeRealization a {
  font-family: "Montserrat-Bold", sans-serif;
  padding: 0px;
  transition: all 0.3s;
}

.seeRealization a:hover {
  color: #4ad681;
}

.portfolioHover {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.portfolioChildHover {
  transition: all .3s;
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
}

.portfolioHover:hover .portfolioChildHover,
.portfolioHover:focus .portfolioChildHover {
  transform: scale(1.2);
}

@media screen and (max-width: 1600px) {
  .mainContainerPortfolio__box {
    min-height: auto;
    width: 94%;
    margin-left: auto;
    margin-right: auto;
  }
  .mainContainerPortfolio__box--left {
    min-height: auto;
    padding-bottom: 40px;
  }
}

@media screen and (max-width: 1200px) {
  .mainContainerPortfolio__box {
    flex-direction: column;
  }
  .mainContainerPortfolio__box--left {
    width: 100%;
    padding-bottom: 40px;
    padding-left: 0px;
    padding-right: 0px;
  }
  .mainContainerPortfolio__box--left h3 {
    padding-right: 20px;
  }
  .mainContainerPortfolio__box--left p {
    padding-right: 20px;
  }
  .mainContainerPortfolio__box--left .seeRealization {
    margin-right: 20px;
  }
  .mainContainerPortfolio__box--right {
    height: 500px;
  }
}

@media screen and (max-width: 768px) {
  .mainContainerPortfolio__box--left {
    min-height: auto;
    padding: 0px;
    padding-bottom: 40px;
  }
  .mainContainerPortfolio__box--left h3 {
    font-size: 32px;
  }
  .mainContainerPortfolio__box--right {
    height: 250px;
  }
}

@media screen and (max-width: 500px) {
  .mainContainerPortfolio__box--left h3, .mainContainerPortfolio__box--left p {
    padding: 15px;
  }
  .mainContainerPortfolio__box--left .seeRealization {
    margin-right: 15px;
  }
}

.pagination {
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
  margin-top: 30px;
}

.pagination__list {
  display: flex;
  font-size: 36px;
  font-family: "Montserrat-Bold", sans-serif;
}

.pagination__list li {
  margin: 0px 10px;
  width: 40px;
  height: 40px;
  transition: all 0.3s;
}

.pagination__list li a {
  float: left;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pagination__list li:hover {
  background-color: #4ad681;
}

.pagination__list li:hover a {
  color: #fff;
}

.pagination__list--active {
  background-color: #4ad681;
}

.pagination__list--active a {
  color: #fff;
}

@media screen and (max-width: 768px) {
  .pagination__list {
    font-size: 24px;
  }
  .pagination__list li {
    width: 30px;
    height: 30px;
    margin: 0 5px;
  }
}

.otherBlogs {
  background-color: #fff;
  padding-top: 0px;
}

.otherBlogs .swiper-slider-realizationsPhoto {
  background-size: cover;
}

.otherBlogs .swiper-slide h3 {
  padding-left: 125px;
  font-size: 75px;
  font-family: "Montserrat-Bold", sans-serif;
  margin-top: -50px;
}

.otherBlogs .swiper-slide p {
  padding-left: 125px;
}

.otherBlogs__seeMore {
  position: relative;
  margin-left: 125px;
  left: auto;
  bottom: auto;
}

@media screen and (max-width: 768px) {
  .otherBlogs .swiper-slide h3 {
    padding-left: 0px;
  }
  .otherBlogs .swiper-slide p {
    padding-left: 0px;
  }
  .otherBlogs__seeMore {
    margin-left: 0px;
  }
}

.contact p {
  font-family: "Montserrat-Light", sans-serif;
  font-size: 16px;
  line-height: 32px;
}

.contact a {
  color: #fff;
}

.mapaContact {
  position: relative;
}

.background-map {
  border-right: 12px solid #f1f1f1;
}

.border-map {
  width: auto;
  height: 13px;
  background-color: #f1f1f1;
  margin-left: 32.7%;
}

.form {
  width: 100%;
  height: 830px;
  background-image: url(../images/kontakt.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin: 60px 0px;
  display: flex;
}

.form__title {
  width: 33.3333%;
  height: 100%;
  padding-top: 5vw;
}

.form__title .mainTitle {
  width: auto;
  font-family: "Montserrat-Bold", sans-serif;
  margin-left: 100px;
}

.form__middle {
  width: 26.3333%;
  height: 100%;
  padding-top: 10vw;
  padding-right: 7%;
}

.form__middle--input {
  margin-bottom: 40px;
}

.form__middle--input h4 {
  font-family: "Montserrat-Regular", sans-serif;
  font-size: 14px;
  margin-bottom: 15px;
  transition: all 0.3s;
}

.form__middle--input input {
  background-color: transparent;
  border: none;
  width: 100%;
  border-bottom: 1px solid #000;
  color: #000;
  margin-bottom: 10px;
}

.form__middle--input input:focus {
  outline: none;
}

.form__middle--input textarea {
  background-color: transparent;
  border: none;
  width: 100%;
  border-bottom: 1px solid #000;
  height: 100px;
  font-family: "Montserrat-Light", sans-serif;
}

.form__middle--input textarea:focus {
  outline: none;
}

.form .headerActive {
  color: #4ad681;
  font-weight: bold;
  transform: scale(1.2);
}

.form__right {
  width: 28.3333%;
  height: 100%;
  padding-right: 7%;
  padding-top: 10vw;
}

.error {
  color: #4ad681;
  font-family: "Montserrat-Regular", sans-serif;
  font-size: 14px;
}

#sendMessage {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: none;
  padding: 20px 0px;
  margin-top: 100px;
  position: relative;
  font-family: "Montserrat-Regular", sans-serif;
  text-align: center;
  border-bottom: 10px solid #4ad681;
}

#sendMessage:focus {
  outline: none;
}

#sendMessage:hover {
  cursor: pointer;
  color: #fff;
}

#sendMessage::after {
  content: "";
  position: absolute;
  bottom: -10px;
  left: 0;
  width: 100%;
  height: 100%;
  transform: scaleY(0);
  transform-origin: bottom center;
  background-color: #4ad681;
  z-index: 1;
  transition: all 0.3s;
}

#sendMessage:hover::after {
  transform: scaleY(1);
  z-index: 1;
  bottom: 0px;
}

#sendMessage:hover p {
  color: #fff;
  z-index: 10;
}

@media screen and (max-width: 1500px) {
  .form {
    height: 530px;
  }
  .border-map {
    display: none;
  }
  .background-map {
    border: none;
  }
}

@media screen and (max-width: 1300px) {
  .form {
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0px;
    height: 840px;
    margin: 5px 0px;
    padding-bottom: 30px;
    padding-left: 15px;
  }
  .form__middle {
    padding: 0px;
    width: 500px;
    height: auto;
  }
  .form__right {
    width: 500px;
    padding: 0px;
    height: auto;
  }
  .form__title {
    width: 500px;
    height: auto;
    margin: 40px 0px;
    padding-top: 0px;
  }
  .form__title .mainTitle {
    margin-left: 0px;
  }
  #sendMessage {
    margin-top: 50px;
  }
  .contactHeader__left {
    width: 100%;
  }
  .contactHeader__left .mainPhotoHeader__left--background {
    min-height: 340px;
  }
  .contactHeader__left .mainPhotoHeader__left--scroll {
    display: none;
  }
  .contactHeader .mainPhotoHeader__right {
    width: 100%;
    min-height: 400px;
  }
}

@media screen and (max-width: 1100px) {
  .contactHeader {
    flex-direction: column;
  }
  .mainPhotoHeader {
    height: auto;
  }
}

@media screen and (max-width: 768px) {
  .contact {
    padding: 15px;
  }
  .form {
    height: auto;
  }
  .form h4 {
    transform-origin: 0% 10%;
  }
  .form__title {
    padding-top: 20px;
  }
}

@media screen and (max-width: 550px) {
  .contactHeader {
    height: auto;
  }
  .contactHeader .mainPhotoHeader__left--background {
    height: auto;
  }
  .contact {
    position: relative;
  }
  .contact p {
    font-size: 14px;
  }
  .form {
    margin-bottom: 0px;
  }
  .form__middle {
    width: 100%;
  }
  .form__right {
    width: 100%;
  }
  .form__title {
    width: 100%;
  }
  .form__title .mainTitle {
    margin-left: 0px;
  }
}

[data-aos][data-aos][data-aos-duration="50"], body[data-aos-duration="50"] [data-aos] {
  transition-duration: 50ms;
}

[data-aos][data-aos][data-aos-delay="50"], body[data-aos-delay="50"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="50"].aos-animate, body[data-aos-delay="50"] [data-aos].aos-animate {
  transition-delay: 50ms;
}

[data-aos][data-aos][data-aos-duration="100"], body[data-aos-duration="100"] [data-aos] {
  transition-duration: .1s;
}

[data-aos][data-aos][data-aos-delay="100"], body[data-aos-delay="100"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="100"].aos-animate, body[data-aos-delay="100"] [data-aos].aos-animate {
  transition-delay: .1s;
}

[data-aos][data-aos][data-aos-duration="150"], body[data-aos-duration="150"] [data-aos] {
  transition-duration: .15s;
}

[data-aos][data-aos][data-aos-delay="150"], body[data-aos-delay="150"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="150"].aos-animate, body[data-aos-delay="150"] [data-aos].aos-animate {
  transition-delay: .15s;
}

[data-aos][data-aos][data-aos-duration="200"], body[data-aos-duration="200"] [data-aos] {
  transition-duration: .2s;
}

[data-aos][data-aos][data-aos-delay="200"], body[data-aos-delay="200"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="200"].aos-animate, body[data-aos-delay="200"] [data-aos].aos-animate {
  transition-delay: .2s;
}

[data-aos][data-aos][data-aos-duration="250"], body[data-aos-duration="250"] [data-aos] {
  transition-duration: .25s;
}

[data-aos][data-aos][data-aos-delay="250"], body[data-aos-delay="250"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="250"].aos-animate, body[data-aos-delay="250"] [data-aos].aos-animate {
  transition-delay: .25s;
}

[data-aos][data-aos][data-aos-duration="300"], body[data-aos-duration="300"] [data-aos] {
  transition-duration: .3s;
}

[data-aos][data-aos][data-aos-delay="300"], body[data-aos-delay="300"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="300"].aos-animate, body[data-aos-delay="300"] [data-aos].aos-animate {
  transition-delay: .3s;
}

[data-aos][data-aos][data-aos-duration="350"], body[data-aos-duration="350"] [data-aos] {
  transition-duration: .35s;
}

[data-aos][data-aos][data-aos-delay="350"], body[data-aos-delay="350"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="350"].aos-animate, body[data-aos-delay="350"] [data-aos].aos-animate {
  transition-delay: .35s;
}

[data-aos][data-aos][data-aos-duration="400"], body[data-aos-duration="400"] [data-aos] {
  transition-duration: .4s;
}

[data-aos][data-aos][data-aos-delay="400"], body[data-aos-delay="400"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="400"].aos-animate, body[data-aos-delay="400"] [data-aos].aos-animate {
  transition-delay: .4s;
}

[data-aos][data-aos][data-aos-duration="450"], body[data-aos-duration="450"] [data-aos] {
  transition-duration: .45s;
}

[data-aos][data-aos][data-aos-delay="450"], body[data-aos-delay="450"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="450"].aos-animate, body[data-aos-delay="450"] [data-aos].aos-animate {
  transition-delay: .45s;
}

[data-aos][data-aos][data-aos-duration="500"], body[data-aos-duration="500"] [data-aos] {
  transition-duration: .5s;
}

[data-aos][data-aos][data-aos-delay="500"], body[data-aos-delay="500"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="500"].aos-animate, body[data-aos-delay="500"] [data-aos].aos-animate {
  transition-delay: .5s;
}

[data-aos][data-aos][data-aos-duration="550"], body[data-aos-duration="550"] [data-aos] {
  transition-duration: .55s;
}

[data-aos][data-aos][data-aos-delay="550"], body[data-aos-delay="550"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="550"].aos-animate, body[data-aos-delay="550"] [data-aos].aos-animate {
  transition-delay: .55s;
}

[data-aos][data-aos][data-aos-duration="600"], body[data-aos-duration="600"] [data-aos] {
  transition-duration: .6s;
}

[data-aos][data-aos][data-aos-delay="600"], body[data-aos-delay="600"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="600"].aos-animate, body[data-aos-delay="600"] [data-aos].aos-animate {
  transition-delay: .6s;
}

[data-aos][data-aos][data-aos-duration="650"], body[data-aos-duration="650"] [data-aos] {
  transition-duration: .65s;
}

[data-aos][data-aos][data-aos-delay="650"], body[data-aos-delay="650"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="650"].aos-animate, body[data-aos-delay="650"] [data-aos].aos-animate {
  transition-delay: .65s;
}

[data-aos][data-aos][data-aos-duration="700"], body[data-aos-duration="700"] [data-aos] {
  transition-duration: .7s;
}

[data-aos][data-aos][data-aos-delay="700"], body[data-aos-delay="700"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="700"].aos-animate, body[data-aos-delay="700"] [data-aos].aos-animate {
  transition-delay: .7s;
}

[data-aos][data-aos][data-aos-duration="750"], body[data-aos-duration="750"] [data-aos] {
  transition-duration: .75s;
}

[data-aos][data-aos][data-aos-delay="750"], body[data-aos-delay="750"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="750"].aos-animate, body[data-aos-delay="750"] [data-aos].aos-animate {
  transition-delay: .75s;
}

[data-aos][data-aos][data-aos-duration="800"], body[data-aos-duration="800"] [data-aos] {
  transition-duration: .8s;
}

[data-aos][data-aos][data-aos-delay="800"], body[data-aos-delay="800"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="800"].aos-animate, body[data-aos-delay="800"] [data-aos].aos-animate {
  transition-delay: .8s;
}

[data-aos][data-aos][data-aos-duration="850"], body[data-aos-duration="850"] [data-aos] {
  transition-duration: .85s;
}

[data-aos][data-aos][data-aos-delay="850"], body[data-aos-delay="850"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="850"].aos-animate, body[data-aos-delay="850"] [data-aos].aos-animate {
  transition-delay: .85s;
}

[data-aos][data-aos][data-aos-duration="900"], body[data-aos-duration="900"] [data-aos] {
  transition-duration: .9s;
}

[data-aos][data-aos][data-aos-delay="900"], body[data-aos-delay="900"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="900"].aos-animate, body[data-aos-delay="900"] [data-aos].aos-animate {
  transition-delay: .9s;
}

[data-aos][data-aos][data-aos-duration="950"], body[data-aos-duration="950"] [data-aos] {
  transition-duration: .95s;
}

[data-aos][data-aos][data-aos-delay="950"], body[data-aos-delay="950"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="950"].aos-animate, body[data-aos-delay="950"] [data-aos].aos-animate {
  transition-delay: .95s;
}

[data-aos][data-aos][data-aos-duration="1000"], body[data-aos-duration="1000"] [data-aos] {
  transition-duration: 1s;
}

[data-aos][data-aos][data-aos-delay="1000"], body[data-aos-delay="1000"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1000"].aos-animate, body[data-aos-delay="1000"] [data-aos].aos-animate {
  transition-delay: 1s;
}

[data-aos][data-aos][data-aos-duration="1050"], body[data-aos-duration="1050"] [data-aos] {
  transition-duration: 1.05s;
}

[data-aos][data-aos][data-aos-delay="1050"], body[data-aos-delay="1050"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1050"].aos-animate, body[data-aos-delay="1050"] [data-aos].aos-animate {
  transition-delay: 1.05s;
}

[data-aos][data-aos][data-aos-duration="1100"], body[data-aos-duration="1100"] [data-aos] {
  transition-duration: 1.1s;
}

[data-aos][data-aos][data-aos-delay="1100"], body[data-aos-delay="1100"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1100"].aos-animate, body[data-aos-delay="1100"] [data-aos].aos-animate {
  transition-delay: 1.1s;
}

[data-aos][data-aos][data-aos-duration="1150"], body[data-aos-duration="1150"] [data-aos] {
  transition-duration: 1.15s;
}

[data-aos][data-aos][data-aos-delay="1150"], body[data-aos-delay="1150"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1150"].aos-animate, body[data-aos-delay="1150"] [data-aos].aos-animate {
  transition-delay: 1.15s;
}

[data-aos][data-aos][data-aos-duration="1200"], body[data-aos-duration="1200"] [data-aos] {
  transition-duration: 1.2s;
}

[data-aos][data-aos][data-aos-delay="1200"], body[data-aos-delay="1200"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1200"].aos-animate, body[data-aos-delay="1200"] [data-aos].aos-animate {
  transition-delay: 1.2s;
}

[data-aos][data-aos][data-aos-duration="1250"], body[data-aos-duration="1250"] [data-aos] {
  transition-duration: 1.25s;
}

[data-aos][data-aos][data-aos-delay="1250"], body[data-aos-delay="1250"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1250"].aos-animate, body[data-aos-delay="1250"] [data-aos].aos-animate {
  transition-delay: 1.25s;
}

[data-aos][data-aos][data-aos-duration="1300"], body[data-aos-duration="1300"] [data-aos] {
  transition-duration: 1.3s;
}

[data-aos][data-aos][data-aos-delay="1300"], body[data-aos-delay="1300"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1300"].aos-animate, body[data-aos-delay="1300"] [data-aos].aos-animate {
  transition-delay: 1.3s;
}

[data-aos][data-aos][data-aos-duration="1350"], body[data-aos-duration="1350"] [data-aos] {
  transition-duration: 1.35s;
}

[data-aos][data-aos][data-aos-delay="1350"], body[data-aos-delay="1350"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1350"].aos-animate, body[data-aos-delay="1350"] [data-aos].aos-animate {
  transition-delay: 1.35s;
}

[data-aos][data-aos][data-aos-duration="1400"], body[data-aos-duration="1400"] [data-aos] {
  transition-duration: 1.4s;
}

[data-aos][data-aos][data-aos-delay="1400"], body[data-aos-delay="1400"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1400"].aos-animate, body[data-aos-delay="1400"] [data-aos].aos-animate {
  transition-delay: 1.4s;
}

[data-aos][data-aos][data-aos-duration="1450"], body[data-aos-duration="1450"] [data-aos] {
  transition-duration: 1.45s;
}

[data-aos][data-aos][data-aos-delay="1450"], body[data-aos-delay="1450"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1450"].aos-animate, body[data-aos-delay="1450"] [data-aos].aos-animate {
  transition-delay: 1.45s;
}

[data-aos][data-aos][data-aos-duration="1500"], body[data-aos-duration="1500"] [data-aos] {
  transition-duration: 1.5s;
}

[data-aos][data-aos][data-aos-delay="1500"], body[data-aos-delay="1500"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1500"].aos-animate, body[data-aos-delay="1500"] [data-aos].aos-animate {
  transition-delay: 1.5s;
}

[data-aos][data-aos][data-aos-duration="1550"], body[data-aos-duration="1550"] [data-aos] {
  transition-duration: 1.55s;
}

[data-aos][data-aos][data-aos-delay="1550"], body[data-aos-delay="1550"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1550"].aos-animate, body[data-aos-delay="1550"] [data-aos].aos-animate {
  transition-delay: 1.55s;
}

[data-aos][data-aos][data-aos-duration="1600"], body[data-aos-duration="1600"] [data-aos] {
  transition-duration: 1.6s;
}

[data-aos][data-aos][data-aos-delay="1600"], body[data-aos-delay="1600"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1600"].aos-animate, body[data-aos-delay="1600"] [data-aos].aos-animate {
  transition-delay: 1.6s;
}

[data-aos][data-aos][data-aos-duration="1650"], body[data-aos-duration="1650"] [data-aos] {
  transition-duration: 1.65s;
}

[data-aos][data-aos][data-aos-delay="1650"], body[data-aos-delay="1650"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1650"].aos-animate, body[data-aos-delay="1650"] [data-aos].aos-animate {
  transition-delay: 1.65s;
}

[data-aos][data-aos][data-aos-duration="1700"], body[data-aos-duration="1700"] [data-aos] {
  transition-duration: 1.7s;
}

[data-aos][data-aos][data-aos-delay="1700"], body[data-aos-delay="1700"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1700"].aos-animate, body[data-aos-delay="1700"] [data-aos].aos-animate {
  transition-delay: 1.7s;
}

[data-aos][data-aos][data-aos-duration="1750"], body[data-aos-duration="1750"] [data-aos] {
  transition-duration: 1.75s;
}

[data-aos][data-aos][data-aos-delay="1750"], body[data-aos-delay="1750"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1750"].aos-animate, body[data-aos-delay="1750"] [data-aos].aos-animate {
  transition-delay: 1.75s;
}

[data-aos][data-aos][data-aos-duration="1800"], body[data-aos-duration="1800"] [data-aos] {
  transition-duration: 1.8s;
}

[data-aos][data-aos][data-aos-delay="1800"], body[data-aos-delay="1800"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1800"].aos-animate, body[data-aos-delay="1800"] [data-aos].aos-animate {
  transition-delay: 1.8s;
}

[data-aos][data-aos][data-aos-duration="1850"], body[data-aos-duration="1850"] [data-aos] {
  transition-duration: 1.85s;
}

[data-aos][data-aos][data-aos-delay="1850"], body[data-aos-delay="1850"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1850"].aos-animate, body[data-aos-delay="1850"] [data-aos].aos-animate {
  transition-delay: 1.85s;
}

[data-aos][data-aos][data-aos-duration="1900"], body[data-aos-duration="1900"] [data-aos] {
  transition-duration: 1.9s;
}

[data-aos][data-aos][data-aos-delay="1900"], body[data-aos-delay="1900"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1900"].aos-animate, body[data-aos-delay="1900"] [data-aos].aos-animate {
  transition-delay: 1.9s;
}

[data-aos][data-aos][data-aos-duration="1950"], body[data-aos-duration="1950"] [data-aos] {
  transition-duration: 1.95s;
}

[data-aos][data-aos][data-aos-delay="1950"], body[data-aos-delay="1950"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1950"].aos-animate, body[data-aos-delay="1950"] [data-aos].aos-animate {
  transition-delay: 1.95s;
}

[data-aos][data-aos][data-aos-duration="2000"], body[data-aos-duration="2000"] [data-aos] {
  transition-duration: 2s;
}

[data-aos][data-aos][data-aos-delay="2000"], body[data-aos-delay="2000"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2000"].aos-animate, body[data-aos-delay="2000"] [data-aos].aos-animate {
  transition-delay: 2s;
}

[data-aos][data-aos][data-aos-duration="2050"], body[data-aos-duration="2050"] [data-aos] {
  transition-duration: 2.05s;
}

[data-aos][data-aos][data-aos-delay="2050"], body[data-aos-delay="2050"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2050"].aos-animate, body[data-aos-delay="2050"] [data-aos].aos-animate {
  transition-delay: 2.05s;
}

[data-aos][data-aos][data-aos-duration="2100"], body[data-aos-duration="2100"] [data-aos] {
  transition-duration: 2.1s;
}

[data-aos][data-aos][data-aos-delay="2100"], body[data-aos-delay="2100"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2100"].aos-animate, body[data-aos-delay="2100"] [data-aos].aos-animate {
  transition-delay: 2.1s;
}

[data-aos][data-aos][data-aos-duration="2150"], body[data-aos-duration="2150"] [data-aos] {
  transition-duration: 2.15s;
}

[data-aos][data-aos][data-aos-delay="2150"], body[data-aos-delay="2150"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2150"].aos-animate, body[data-aos-delay="2150"] [data-aos].aos-animate {
  transition-delay: 2.15s;
}

[data-aos][data-aos][data-aos-duration="2200"], body[data-aos-duration="2200"] [data-aos] {
  transition-duration: 2.2s;
}

[data-aos][data-aos][data-aos-delay="2200"], body[data-aos-delay="2200"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2200"].aos-animate, body[data-aos-delay="2200"] [data-aos].aos-animate {
  transition-delay: 2.2s;
}

[data-aos][data-aos][data-aos-duration="2250"], body[data-aos-duration="2250"] [data-aos] {
  transition-duration: 2.25s;
}

[data-aos][data-aos][data-aos-delay="2250"], body[data-aos-delay="2250"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2250"].aos-animate, body[data-aos-delay="2250"] [data-aos].aos-animate {
  transition-delay: 2.25s;
}

[data-aos][data-aos][data-aos-duration="2300"], body[data-aos-duration="2300"] [data-aos] {
  transition-duration: 2.3s;
}

[data-aos][data-aos][data-aos-delay="2300"], body[data-aos-delay="2300"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2300"].aos-animate, body[data-aos-delay="2300"] [data-aos].aos-animate {
  transition-delay: 2.3s;
}

[data-aos][data-aos][data-aos-duration="2350"], body[data-aos-duration="2350"] [data-aos] {
  transition-duration: 2.35s;
}

[data-aos][data-aos][data-aos-delay="2350"], body[data-aos-delay="2350"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2350"].aos-animate, body[data-aos-delay="2350"] [data-aos].aos-animate {
  transition-delay: 2.35s;
}

[data-aos][data-aos][data-aos-duration="2400"], body[data-aos-duration="2400"] [data-aos] {
  transition-duration: 2.4s;
}

[data-aos][data-aos][data-aos-delay="2400"], body[data-aos-delay="2400"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2400"].aos-animate, body[data-aos-delay="2400"] [data-aos].aos-animate {
  transition-delay: 2.4s;
}

[data-aos][data-aos][data-aos-duration="2450"], body[data-aos-duration="2450"] [data-aos] {
  transition-duration: 2.45s;
}

[data-aos][data-aos][data-aos-delay="2450"], body[data-aos-delay="2450"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2450"].aos-animate, body[data-aos-delay="2450"] [data-aos].aos-animate {
  transition-delay: 2.45s;
}

[data-aos][data-aos][data-aos-duration="2500"], body[data-aos-duration="2500"] [data-aos] {
  transition-duration: 2.5s;
}

[data-aos][data-aos][data-aos-delay="2500"], body[data-aos-delay="2500"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2500"].aos-animate, body[data-aos-delay="2500"] [data-aos].aos-animate {
  transition-delay: 2.5s;
}

[data-aos][data-aos][data-aos-duration="2550"], body[data-aos-duration="2550"] [data-aos] {
  transition-duration: 2.55s;
}

[data-aos][data-aos][data-aos-delay="2550"], body[data-aos-delay="2550"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2550"].aos-animate, body[data-aos-delay="2550"] [data-aos].aos-animate {
  transition-delay: 2.55s;
}

[data-aos][data-aos][data-aos-duration="2600"], body[data-aos-duration="2600"] [data-aos] {
  transition-duration: 2.6s;
}

[data-aos][data-aos][data-aos-delay="2600"], body[data-aos-delay="2600"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2600"].aos-animate, body[data-aos-delay="2600"] [data-aos].aos-animate {
  transition-delay: 2.6s;
}

[data-aos][data-aos][data-aos-duration="2650"], body[data-aos-duration="2650"] [data-aos] {
  transition-duration: 2.65s;
}

[data-aos][data-aos][data-aos-delay="2650"], body[data-aos-delay="2650"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2650"].aos-animate, body[data-aos-delay="2650"] [data-aos].aos-animate {
  transition-delay: 2.65s;
}

[data-aos][data-aos][data-aos-duration="2700"], body[data-aos-duration="2700"] [data-aos] {
  transition-duration: 2.7s;
}

[data-aos][data-aos][data-aos-delay="2700"], body[data-aos-delay="2700"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2700"].aos-animate, body[data-aos-delay="2700"] [data-aos].aos-animate {
  transition-delay: 2.7s;
}

[data-aos][data-aos][data-aos-duration="2750"], body[data-aos-duration="2750"] [data-aos] {
  transition-duration: 2.75s;
}

[data-aos][data-aos][data-aos-delay="2750"], body[data-aos-delay="2750"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2750"].aos-animate, body[data-aos-delay="2750"] [data-aos].aos-animate {
  transition-delay: 2.75s;
}

[data-aos][data-aos][data-aos-duration="2800"], body[data-aos-duration="2800"] [data-aos] {
  transition-duration: 2.8s;
}

[data-aos][data-aos][data-aos-delay="2800"], body[data-aos-delay="2800"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2800"].aos-animate, body[data-aos-delay="2800"] [data-aos].aos-animate {
  transition-delay: 2.8s;
}

[data-aos][data-aos][data-aos-duration="2850"], body[data-aos-duration="2850"] [data-aos] {
  transition-duration: 2.85s;
}

[data-aos][data-aos][data-aos-delay="2850"], body[data-aos-delay="2850"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2850"].aos-animate, body[data-aos-delay="2850"] [data-aos].aos-animate {
  transition-delay: 2.85s;
}

[data-aos][data-aos][data-aos-duration="2900"], body[data-aos-duration="2900"] [data-aos] {
  transition-duration: 2.9s;
}

[data-aos][data-aos][data-aos-delay="2900"], body[data-aos-delay="2900"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2900"].aos-animate, body[data-aos-delay="2900"] [data-aos].aos-animate {
  transition-delay: 2.9s;
}

[data-aos][data-aos][data-aos-duration="2950"], body[data-aos-duration="2950"] [data-aos] {
  transition-duration: 2.95s;
}

[data-aos][data-aos][data-aos-delay="2950"], body[data-aos-delay="2950"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2950"].aos-animate, body[data-aos-delay="2950"] [data-aos].aos-animate {
  transition-delay: 2.95s;
}

[data-aos][data-aos][data-aos-duration="3000"], body[data-aos-duration="3000"] [data-aos] {
  transition-duration: 3s;
}

[data-aos][data-aos][data-aos-delay="3000"], body[data-aos-delay="3000"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="3000"].aos-animate, body[data-aos-delay="3000"] [data-aos].aos-animate {
  transition-delay: 3s;
}

[data-aos][data-aos][data-aos-easing=linear], body[data-aos-easing=linear] [data-aos] {
  transition-timing-function: cubic-bezier(0.25, 0.25, 0.75, 0.75);
}

[data-aos][data-aos][data-aos-easing=ease], body[data-aos-easing=ease] [data-aos] {
  transition-timing-function: ease;
}

[data-aos][data-aos][data-aos-easing=ease-in], body[data-aos-easing=ease-in] [data-aos] {
  transition-timing-function: ease-in;
}

[data-aos][data-aos][data-aos-easing=ease-out], body[data-aos-easing=ease-out] [data-aos] {
  transition-timing-function: ease-out;
}

[data-aos][data-aos][data-aos-easing=ease-in-out], body[data-aos-easing=ease-in-out] [data-aos] {
  transition-timing-function: ease-in-out;
}

[data-aos][data-aos][data-aos-easing=ease-in-back], body[data-aos-easing=ease-in-back] [data-aos] {
  transition-timing-function: cubic-bezier(0.6, -0.28, 0.735, 0.045);
}

[data-aos][data-aos][data-aos-easing=ease-out-back], body[data-aos-easing=ease-out-back] [data-aos] {
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

[data-aos][data-aos][data-aos-easing=ease-in-out-back], body[data-aos-easing=ease-in-out-back] [data-aos] {
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

[data-aos][data-aos][data-aos-easing=ease-in-sine], body[data-aos-easing=ease-in-sine] [data-aos] {
  transition-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715);
}

[data-aos][data-aos][data-aos-easing=ease-out-sine], body[data-aos-easing=ease-out-sine] [data-aos] {
  transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
}

[data-aos][data-aos][data-aos-easing=ease-in-out-sine], body[data-aos-easing=ease-in-out-sine] [data-aos] {
  transition-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95);
}

[data-aos][data-aos][data-aos-easing=ease-in-quad], body[data-aos-easing=ease-in-quad] [data-aos] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
}

[data-aos][data-aos][data-aos-easing=ease-out-quad], body[data-aos-easing=ease-out-quad] [data-aos] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

[data-aos][data-aos][data-aos-easing=ease-in-out-quad], body[data-aos-easing=ease-in-out-quad] [data-aos] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

[data-aos][data-aos][data-aos-easing=ease-in-cubic], body[data-aos-easing=ease-in-cubic] [data-aos] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
}

[data-aos][data-aos][data-aos-easing=ease-out-cubic], body[data-aos-easing=ease-out-cubic] [data-aos] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

[data-aos][data-aos][data-aos-easing=ease-in-out-cubic], body[data-aos-easing=ease-in-out-cubic] [data-aos] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

[data-aos][data-aos][data-aos-easing=ease-in-quart], body[data-aos-easing=ease-in-quart] [data-aos] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
}

[data-aos][data-aos][data-aos-easing=ease-out-quart], body[data-aos-easing=ease-out-quart] [data-aos] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

[data-aos][data-aos][data-aos-easing=ease-in-out-quart], body[data-aos-easing=ease-in-out-quart] [data-aos] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

[data-aos^=fade][data-aos^=fade] {
  opacity: 0;
  transition-property: opacity,transform;
}

[data-aos^=fade][data-aos^=fade].aos-animate {
  opacity: 1;
  transform: translate(0);
}

[data-aos=fade-up] {
  transform: translateY(100px);
}

[data-aos=fade-down] {
  transform: translateY(-100px);
}

[data-aos=fade-right] {
  transform: translate(-100px);
}

[data-aos=fade-left] {
  transform: translate(100px);
}

[data-aos=fade-up-right] {
  transform: translate(-100px, 100px);
}

[data-aos=fade-up-left] {
  transform: translate(100px, 100px);
}

[data-aos=fade-down-right] {
  transform: translate(-100px, -100px);
}

[data-aos=fade-down-left] {
  transform: translate(100px, -100px);
}

[data-aos^=zoom][data-aos^=zoom] {
  opacity: 0;
  transition-property: opacity,transform;
}

[data-aos^=zoom][data-aos^=zoom].aos-animate {
  opacity: 1;
  transform: translate(0) scale(1);
}

[data-aos=zoom-in] {
  transform: scale(0.6);
}

[data-aos=zoom-in-up] {
  transform: translateY(100px) scale(0.6);
}

[data-aos=zoom-in-down] {
  transform: translateY(-100px) scale(0.6);
}

[data-aos=zoom-in-right] {
  transform: translate(-100px) scale(0.6);
}

[data-aos=zoom-in-left] {
  transform: translate(100px) scale(0.6);
}

[data-aos=zoom-out] {
  transform: scale(1.2);
}

[data-aos=zoom-out-up] {
  transform: translateY(100px) scale(1.2);
}

[data-aos=zoom-out-down] {
  transform: translateY(-100px) scale(1.2);
}

[data-aos=zoom-out-right] {
  transform: translate(-100px) scale(1.2);
}

[data-aos=zoom-out-left] {
  transform: translate(100px) scale(1.2);
}

[data-aos^=slide][data-aos^=slide] {
  transition-property: transform;
}

[data-aos^=slide][data-aos^=slide].aos-animate {
  transform: translate(0);
}

[data-aos=slide-up] {
  transform: translateY(100%);
}

[data-aos=slide-down] {
  transform: translateY(-100%);
}

[data-aos=slide-right] {
  transform: translateX(-100%);
}

[data-aos=slide-left] {
  transform: translateX(100%);
}

[data-aos^=flip][data-aos^=flip] {
  backface-visibility: hidden;
  transition-property: transform;
}

[data-aos=flip-left] {
  transform: perspective(2500px) rotateY(-100deg);
}

[data-aos=flip-left].aos-animate {
  transform: perspective(2500px) rotateY(0);
}

[data-aos=flip-right] {
  transform: perspective(2500px) rotateY(100deg);
}

[data-aos=flip-right].aos-animate {
  transform: perspective(2500px) rotateY(0);
}

[data-aos=flip-up] {
  transform: perspective(2500px) rotateX(-100deg);
}

[data-aos=flip-up].aos-animate {
  transform: perspective(2500px) rotateX(0);
}

[data-aos=flip-down] {
  transform: perspective(2500px) rotateX(100deg);
}

[data-aos=flip-down].aos-animate {
  transform: perspective(2500px) rotateX(0);
}

/*# sourceMappingURL=aos.css.map*/
@media screen and (max-width: 1300px) {
  .realizationsSwiper .realizationsPagination {
    left: 0px;
  }
}

@media screen and (max-width: 1024px) {
  .footerContainer:after {
    height: 0px;
  }
  .newScroll {
    left: 15px;
  }
  .aboutUs__swiper--pagination {
    width: 300px !important;
    left: 15px !important;
    right: auto !important;
  }
  .aboutUs__count {
    left: 15px;
  }
  .visualisation {
    height: 500px;
  }
  .mainContainerNew {
    padding: 0 15px;
  }
  .aboutUs__content p {
    width: 450px;
  }
  .mainContainerPortfolio__box--left {
    padding: 0px;
    min-height: 190px;
  }
  #MixItUp7AC9E8 {
    padding: 15px;
  }
  .mainPhotoHeader__left--background--text {
    font-size: 24px;
  }
  .otherProjects {
    padding-right: 0px;
    justify-content: space-around;
  }
  .mainPhotoHeader__left--background--text h2 {
    font-size: 28px;
  }
  .mainPhotoHeader__left--background--text h3 {
    font-size: 17px;
  }
  .howWeWork {
    padding-left: 15px;
    padding-right: 15px;
  }
  .aboutUs__content h3 {
    width: 450px;
  }
  .blog-box-left .mainContainerPortfolio__box--left {
    padding-left: 0px;
    padding-right: 0px;
  }
  .containerBlog {
    margin-top: 50px;
  }
  .containerBlog .mainContainerPortfolio__box--left h3 {
    padding-top: 30px;
  }
  .containerBlog__box .mainContainerPortfolio__box--left .seeRealization {
    margin-bottom: 25px;
  }
  .containerBlog__box {
    margin-bottom: 0px;
  }
  .pagination {
    margin-top: 50px;
  }
  .form {
    height: auto;
    padding-bottom: 20px;
  }
  .contactHeader .mainPhotoHeader__right {
    height: 400px;
    min-height: auto;
  }
  .contactHeader__left .mainPhotoHeader__left--background {
    height: 340px;
    min-height: auto;
  }
  .lastRealization {
    min-height: 550px;
  }
  .lastRealizationContent {
    padding-left: 15px;
  }
  .lastRealization__seeMore {
    left: 0px;
  }
  .containerOnas__seeMore {
    padding-bottom: 50px;
  }
  .mainContainerPortfolio__box--left h3 {
    padding-right: 15px;
    padding-top: 15px;
  }
  .mainContainerPortfolio__box--left p {
    padding-right: 15px;
    width: 80%;
  }
  .mainContainerPortfolio__box--left .seeRealization {
    margin-bottom: 20px;
    margin-right: 15px;
  }
  .mainTitle {
    width: 80%;
  }
  .newContainerPadding {
    padding: 0 15px;
  }
  .newScroll {
    width: 100%;
  }
  .mainPhotoHeader__left--scroll {
    margin-left: 15px;
  }
  .aboutUs .mainTitle {
    margin-left: 0px;
  }
  .visualisation .swiper-slide {
    height: 500px;
  }
  .otherBlogs .lastRealization__header {
    left: 0px;
  }
  .realizationsSwiper .swiper-slider-realizationsPhoto {
    height: 400px;
  }
  .lastRealization {
    min-height: 770px;
  }
  .containerBlog__box {
    height: 750px;
  }
  .otherBlogs .swiper-slide h3 {
    font-size: 45px;
  }
}

@media screen and (max-width: 768px) {
  .mainPhotoHeader__left--scroll {
    margin-left: 0px;
  }
  .mainContainerPortfolio__box--left h3 {
    width: 90%;
    font-size: 26px;
  }
  .aboutUs__content p {
    width: 300px;
  }
  .visualisation {
    height: auto;
  }
  .mainContainerPortfolio__box--left h3 {
    padding: 15px 15px 0px 0px;
  }
  .mainContainerPortfolio__box--left p {
    padding-right: 15px;
    padding-left: 15px;
  }
  .mainContainerPortfolio__box--left .seeRealization {
    margin-right: 15px;
    margin-bottom: 15px;
  }
  .singleRealizacja-photo {
    height: 380px;
  }
  .containerOnas h3 {
    font-size: 24px;
  }
  .containerOnasNew {
    padding: 25px 15px;
  }
  .realizationsSwiper .swiper-slider-realizationsPhoto {
    height: 260px;
  }
  .realizationsSwiper h3 {
    font-size: 24px;
  }
  .aboutUs__swiper {
    padding: 0px;
  }
  .otherBlogs .swiper-slide h3 {
    font-size: 30px;
    margin-top: -50px;
    margin-bottom: 35px;
  }
  .realizationsSwiper .swiper-slider-realizationsPhoto {
    margin-left: 0px;
  }
  .aboutUs__content h3 {
    width: 100%;
  }
  .aboutUs__content p {
    width: 100%;
  }
  .aboutUs {
    padding-left: 0px;
    padding-right: 0px;
  }
  .mainTitle {
    margin-bottom: 20px;
  }
  .mainContainerNew {
    padding: 0px;
  }
  .realizationsSwiper .realizationsPagination {
    left: 0px;
  }
  .lastRealization__header {
    left: 0px;
  }
  .realizationsSwiper h3 {
    margin: 20px 0px;
    padding-left: 0px;
  }
  .realizationsSwiper p {
    padding-left: 0px;
  }
  .realizationsSwiper .swiper-slider-realizationsPhoto {
    width: 97%;
  }
  .form {
    padding: 0px;
    padding-bottom: 20px;
  }
  .contactHeader__left .mainPhotoHeader__left--background {
    height: 350px;
  }
  .form__middle {
    width: 290px;
    margin: 0 auto;
  }
  .form__right {
    width: 290px;
    margin: 0 auto;
  }
  .form__title {
    width: 290px;
    margin: 0 auto;
  }
  .otherBlogs .realizationsSwiper .swiper-slider-realizationsPhoto {
    height: 430px;
  }
  .otherBlogs .swiper-slider {
    padding-bottom: 20px;
  }
  .mainMenu {
    position: inherit;
  }
  .aboutUs__swiper {
    height: 440px;
  }
  .visualisation .swiper-slide {
    height: 280px;
  }
  .mainTitle {
    font-size: 20px;
  }
  .aboutUs__content h3 {
    font-size: 20px;
  }
  .aboutUs__count {
    font-size: 20px;
  }
  .realizationsSwiper h3 {
    font-size: 20px;
  }
  .lastRealization__header {
    font-size: 24px;
  }
  .realizationsSwiper {
    padding-top: 140px;
  }
  .mainContainerPortfolio ul li {
    font-size: 20px;
  }
  .mainContainerPortfolio__box--left p {
    display: none;
  }
  .mainContainerPortfolio__box--left {
    min-height: 100px;
  }
  .mainContainerPortfolio__box--left h3 {
    margin-bottom: 20px;
    font-size: 20px;
  }
  .singleRealizacja-photo {
    height: 64vw;
  }
  .mainPhotoHeader__left--background--text {
    font-size: 20px;
  }
  .normalHeader {
    font-size: 20px;
  }
  .mainPhotoHeader__left--background--text h2 {
    font-size: 22px;
  }
  .containerOnas h3 {
    font-size: 20px;
  }
  .mainPhotoHeader__text {
    font-size: 24px;
  }
  .containerOnasGrey__count--single h4 {
    font-size: 40px;
  }
  .countM2 h6 {
    font-size: 30px;
    margin-bottom: 11px;
  }
}

@media screen and (min-width: 769px) {
  .mainPhotoHeader, .mainPhoto {
    margin-top: 95px;
  }
}

@media screen and (max-width: 1320px) and (min-width: 1250px) {
  .mainContainer {
    padding-left: 50px;
    padding-right: 50px;
    width: auto;
  }
  .containerOnas {
    padding-left: 25.8%;
  }
  .containerOnas p {
    padding-left: 0px;
  }
  .newScroll {
    left: 60px;
  }
  .mainTitle {
    margin-left: 60px;
  }
  .aboutUs__swiper--pagination {
    left: 60px !important;
  }
  .lastRealization__count {
    left: 60px;
  }
  .realizationsSwiper .swiper-slider-realizationsPhoto {
    height: 550px;
  }
  .realizationsSwiper .realizationsPagination {
    left: 50px;
  }
  .lastRealization__seeMore {
    left: 50px;
  }
  .visualisation .aboutUs__count {
    left: 60px;
  }
  .containerOnasGrey__count {
    width: auto;
  }
  .mainTitleNew {
    width: auto;
  }
  .swiper-slide .mainContainer {
    padding: 0px;
    width: 1150px;
  }
}
