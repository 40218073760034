.containerOnas {
  padding-left: 32.8%;
  width: auto;

  h3 {
    font-size: 36px;
    font-family: $fontSB;
    width: 850px;
    margin-bottom: 50px;
  }

  p {
    width: 850px;
    font-family: $fontL;
    padding-bottom: 45px;
    line-height: 32px;
  }

  &__seeMore {
    position: relative !important;
    bottom: auto !important;
    left: auto !important;
    padding-bottom: 12vw;
  }
}

.containerOnasGrey {
  background: #f1f1f1;
  padding-top: 20px;
  min-height: 37vw;
  padding-left: 0%;
  padding-bottom: 30px;
  width: auto;

  p {
    padding-left: 32.7%;
  }

  &__count {
    display: flex;
    justify-content: space-around;
    margin-top: 6vw;
    width: 1250px;
    margin-left: auto;
    margin-right: auto;

    &--single {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-family: $fontR;
      
      h4 {
        font-size: 64px;
        margin-bottom: 10px;
      }

      h5 {
        font-size: 18px;
        text-transform: uppercase;
      }
    }
  }
}

.countM2 {
  display: flex;
  align-items: flex-end;

  h6 {
    font-size: 36px;
    margin-left: 10px;
    margin-bottom: 15px;
  }
}

@media screen and (max-width: 1650px) and (min-width: 1501px) {
  .containerOnasGrey {
    padding-top: 50px;
  }
}
   

@media screen and (max-width: 1500px){
  .containerOnas {
    padding-left: 25.8%;
  }
  .containerOnasGrey {
    padding-left: 0%;

    p {
      padding-left: 25.8%;
    }
  }
}

@media screen and (max-width: 1380px) and (min-width: 1200px) {
  .containerOnasGrey  {

    &__count {
      margin-top: 20px;
    }
  }
 }
    

@media screen and (max-width: 1200px){
  .containerOnas {
    padding: 15px 15px;

    h3 {
      width: auto;
    }
    p {
      width: auto;
    }
  }
  .containerOnasGrey {
    p {
      padding-left: 0px;
    }

    &__count {
      width: auto;

      &--single {

        h4 {
          font-size: 48px;
        }
        h5 {
          font-size: 14px;
        }
      }
    }
  }
}

@media screen and (max-width: 768px){
  .containerOnas {
    padding: 15px 0px;

    &__seeMore {
      display: block !important;
    }
    p {
      padding-bottom: 20px;
    }
  }

  .containerOnasGrey {

    &__count {
      flex-direction: column;
     
      &--single {
        margin-bottom: 30px;
      }
    }
  }
}