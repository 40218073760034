.visualisation {
  height: 63vw;
  width: 100%;
  position: relative;
  // margin-top: 90px;
  // background-repeat: no-repeat;
  // background-size: contain;
  // background-image: url(../images\wizualizacja-1.png);

  .swiper-slide {
    width: 100%;
    height: 100%;
    // background-image: url(../images\wizualizacja-1.png);
    background-repeat: no-repeat;
    background-size: cover;
    margin-top: 90px;
  }
  .swiper-pagination-bullet {
    width: 18px;
    height: 4px;
    border-radius: 0px;
    margin: 0px 15px !important;
  }
  .swiper-pagination-bullet-active {
    background-color: $primary-color;
  }
  .visualisation-pagination {
    top: 5px;
  }
  .aboutUs__count {
    top: 0px;
  }
}

@media screen and (max-width: 1500px){
  .visualisation {

    .aboutUs__count {
      top: 25px;

      width: 1250px;
      margin-left: auto;
      margin-right: auto;
      right: 0;
      left: 0;
    }
  }
}

.howWeWork {
  padding: 90px 0px 0px 0px;

  &__content {
    margin: 100px 0px 0px 0px;

    .aboutUs__content--single {
      margin-bottom: 150px;
      z-index: 1;
    }
  }

  h3 {
    position: relative;
    padding-bottom: 30px;

    &:after {
      content: ' ';
      position: absolute;
      bottom: 0px;
      left: 0px;
      height: 1px;
      background-color: #000;
      width: 235px;
    }
  }
}

.greyBoxContent {
  position: relative;
}

.greyBox {
  height: 350px;
  width: 100%;
  background-color: #f1f1f1;
  position: absolute;
  top: 21.2%;
}



@media screen and (max-width: 1650px) and (min-width: 1501px) {
 .greyBox {
   top: 21%;
 }
}
   

@media screen and (max-width: 1500px){
  .howWeWork {

    &__content {

      .aboutUs__content--single {
        margin-bottom: 100px;
      }
    }
    .mainTitle {
      width: auto;
      margin-left: 0px;
    }
  }
  .greyBox {
    top: 20.5%;
  }
}

@media screen and (max-width: 1300px) and (min-width: 1250px) {
  .howWeWork {

    .mainTitle {
      margin-left: 50px;
    }
  }
 }

@media screen and (max-width: 1200px){
  .greyBox {
    display: none
  }
}

@media screen and (max-width: 1024px){
  .visualisation {

    .aboutUs__count {
      width: 300px;
      right: auto;
      left: 15px;
    }
  }  
}

@media screen and (max-width: 768px){
  .visualisation {
    height: 300px;
    .visualisation-pagination {
      left: 15px;
    }
  }
  
  .howWeWork {
    padding-top: 40px;
    padding-left: 15px;
    padding-right: 15px;
    

    .mainTitle {
      margin-left: 0px;
    }

    h3 {
      padding-bottom: 0px;
    }

    &__content {
      margin-top: 50px;

      .aboutUs__content--single {
        margin-bottom: 60px;
      }
    }
  }
}